import {Grid} from '@material-ui/core';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {AppRoute, routes} from '../../routes';

const appRoutes = routes.map(
  (route: AppRoute, index: number) => {
    return (
      <Route
        key={index}
        exact={true}
        path={route.path}
        component={route.component}
      />
    );
  });

export const Content = () => {
  return (
    <Grid item={true} xs={12}>
      <Switch>
        {appRoutes}
      </Switch>
    </Grid>
  );
};
