import {assoc, compose, flatten, indexBy, map, mergeLeft, union} from 'ramda';
import {AnyAction, combineReducers} from 'redux';
import {CustomerFeatureDto, isGetCustomerFeaturesSuccess} from './actions';
import {CustomerFeatures, CustomerSetting} from './state';

const initialState: CustomerFeatures = {
  features: {},
  settingsByCode: {},
  settingsByCustomerId: {}
};

// Utility functions for parsing raw server responses

/**
 * Generates the state key for a customer setting
 * @param customerId The customerId of the customer
 * @param settingCode The code for the setting
 * @return The compound key for the customer setting
 */
export function customerSettingsKey(customerId: string, settingCode: string): string {
  return `${customerId}.${settingCode}`;
}

const mapSettings = (customerId: string) => (cf: CustomerFeatureDto) => cf.settings.map(cs => ({
  customerId,
  featureCode: cf.code,
  settingCode: cf.code + '.' + cs.code,
  value: cs.value
}));
const customerFeatureToCustomerSettings = (customerId: string) =>
  (cfs: CustomerFeatureDto[]) => flatten(map(mapSettings(customerId), cfs));
const indexByCompoundKey = indexBy((cs: CustomerSetting) => customerSettingsKey(cs.customerId, cs.settingCode));
const parseCustomerSettings = (customerId: string) => compose(
  indexByCompoundKey,
  customerFeatureToCustomerSettings(customerId)
);

// Reducers

function settingsByCode(state = initialState.settingsByCode, action: AnyAction) {
  if (isGetCustomerFeaturesSuccess(action)) {
    const customerId = action.meta.previousAction.customerId;
    return mergeLeft(parseCustomerSettings(customerId)(action.payload.data), state);
  }

  return state;
}
function settingsByCustomerId(state = initialState.settingsByCustomerId, action: AnyAction) {
  if (isGetCustomerFeaturesSuccess(action)) {
    const customerId = action.meta.previousAction.customerId;
    return assoc(customerId, Object.keys(parseCustomerSettings(customerId)(action.payload.data)), state);
  }

  return state;
}
function features(state = initialState.features, action: AnyAction) {
  if (isGetCustomerFeaturesSuccess(action)) {
    const customerId = action.meta.previousAction.customerId;
    return assoc(customerId, union(state[customerId], action.payload.data.map(cf => cf.code)), state);
  }

  return state;
}

export const customerFeatures = combineReducers({
  features,
  settingsByCode,
  settingsByCustomerId
});
