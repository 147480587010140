import { Container, Typography } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getCustomerFeatures } from '../customer-features/actions';
import { getFeatures } from '../features/actions';
import { getSettingsByLocationCode } from '../location-settings/actions';
import { AxiosDispatch } from '../middleware/axios';
import { State } from '../state';
import LocationSettings from './LocationSettings';
import LocationTree from './LocationTree';
import { LocationSetting } from './state';
import messages from './translations';

export interface Props extends RouteComponentProps<{ customerId: string }> {
  dispatch: AxiosDispatch & ThunkDispatch<{}, {}, AnyAction>;
  settingsByLocationCode: State['locationSettings']['settingsByLocationCode'];
}

export const LocationList = (props: Props) => {
  const { formatMessage } = useIntl();
  const { dispatch, settingsByLocationCode } = props;

  const [selectedLocation, setSelectedLocation] = useState<
    string | undefined
  >();

  const customerId = props.match.params.customerId;

  useEffect(() => {
    // gets features and its settings with default value of setting
    dispatch(getFeatures(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCustomerFeatures(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    if (selectedLocation) {
      dispatch(getSettingsByLocationCode(selectedLocation));
    }
  }, [dispatch, customerId, selectedLocation]);

  const onSelect = useCallback(
    (newSelection) => setSelectedLocation(newSelection),
    [setSelectedLocation]
  );

  const locationSettingsByCode = (locationCode: string) => {
    if (!settingsByLocationCode[locationCode]) {
      return [];
    }

    const data: LocationSetting[] = settingsByLocationCode[locationCode].map(
      (setting) => {
        return {
          customerValue: setting.customerValue,
          defaultValue: setting.defaultValue,
          description: setting.description,
          featureCode: setting.featureCode,
          featureName: setting.featureName,
          id: `${setting.featureCode}.${setting.code}`,
          isLocation: setting.isLocation,
          locationValue: setting.location?.locationValue,
          settingCode: setting.code,
          settingName: setting.name,
          settingType: setting.type
        };
      }
    );
    return data;
  };

  return (
    <>
      <Container maxWidth="lg">
        <Typography
          component="h2"
          variant="h5"
          align="left"
          color="textPrimary"
          gutterBottom={true}
        >
          {formatMessage(messages['locations.title'])}
        </Typography>
        <LocationTree customerId={customerId} onSelect={onSelect} />
        {selectedLocation ? (
          <LocationSettings
            locationCode={selectedLocation}
            locationSettings={locationSettingsByCode(selectedLocation)}
          />
        ) : null}
      </Container>
    </>
  );
};

const mapStateToProps = (state: State) => ({
  settingsByLocationCode: state.locationSettings.settingsByLocationCode
});

const mapDispatchToProps = (
  dispatch: AxiosDispatch & ThunkDispatch<{}, {}, AnyAction>
) => ({
  dispatch
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocationList)
);
