import { AxiosRequestConfig } from 'axios';
import { AnyAction } from 'redux';
import { AxiosAction, AxiosSuccessAction } from '../middleware/axios';
import { LocationSettingDto } from './state';

// Action Types

const GET_LOCATION_SETTINGS = 'GET_LOCATION_SETTINGS_FOR_LOCATION';
const GET_LOCATION_SETTINGS_SUCCESS = `${GET_LOCATION_SETTINGS}_SUCCESS`;

// Action Interfaces

export interface GetSettingsByLocationCodeAction extends AxiosAction {
  locationCode: string;
  type: string;
}

export interface GetSettingsByLocationCodeSuccessAction
  extends AxiosSuccessAction<
    LocationSettingDto[],
    GetSettingsByLocationCodeAction
  > {
  type: string;
}

// Action Creators

// get location settings for a specific locationId
export function getSettingsByLocationCode(
  locationCode: string
): GetSettingsByLocationCodeAction {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/locations/${locationCode}/settings`
  };

  return {
    locationCode,
    payload: {
      client: 'customerProfile',
      request
    },
    type: GET_LOCATION_SETTINGS
  };
}

export function isGetSettingsByLocationSuccess(
  action: AnyAction
): action is GetSettingsByLocationCodeSuccessAction {
  return action.type === GET_LOCATION_SETTINGS_SUCCESS;
}
