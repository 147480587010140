import { AnyAction } from 'redux';
import { isTokenUpdate } from './actions';
import { AuthState } from './state';

const initialState: AuthState = {
  authenticated: false
};

export function auth(state = initialState, action: AnyAction): AuthState {
  if (isTokenUpdate(action)) {
    return {
      authenticated: true,
      getTokenFunction: action.getTokenFunction,
      logoutFunction: action.logoutFunction,
      updateTokenFunction: action.updateTokenFunction
    };
  }

  return state;
}
