import {Key} from 'react';
import {AnyAction} from 'redux';
import {v4 as uuid} from 'uuid';
import {Notification} from './state';

export const CREATE_SNACKBAR = 'SNACKBAR.CREATE';
export const CLOSE_SNACKBAR = 'SNACKBAR.CLOSE';
export const CLOSE_ALL_SNACKBARS = 'SNACKBAR.CLOSE_ALL';
export const DISPLAY_SNACKBAR = 'SNACKBAR.DISPLAY';
export const REMOVE_SNACKBAR = 'SNACKBAR.REMOVE';

export interface CreateSnackbar {
  key: Key;
  notification: Notification;
  type: typeof CREATE_SNACKBAR;
}

export interface CloseSnackbar {
  key: Key;
  type: typeof CLOSE_SNACKBAR;
}

export interface CloseAllSnackbars {
  type: typeof CLOSE_ALL_SNACKBARS;
}

export interface DisplaySnackbar {
  key: Key;
  type: typeof DISPLAY_SNACKBAR;
}

export interface RemoveSnackbar {
  key: Key;
  type: typeof REMOVE_SNACKBAR;
}

export function createSnackbar(notification: Notification): CreateSnackbar {
  const key = notification.options && notification.options.key;

  return {
    key: key || uuid(),
    notification,
    type: CREATE_SNACKBAR
  };
}

export function displaySnackbar(key: Key): DisplaySnackbar {
  return {
    key,
    type: DISPLAY_SNACKBAR
  };
}

export function closeSnackbar(key: Key): CloseSnackbar {
  return {
    key,
    type: CLOSE_SNACKBAR
  };
}

export function closeAllSnackbars(): CloseAllSnackbars {
  return {
    type: CLOSE_ALL_SNACKBARS
  };
}

export const removeSnackbar = (key: Key) => ({
  key,
  type: REMOVE_SNACKBAR
});

export function isCreateSnackbarAction(action: AnyAction): action is CreateSnackbar {
  return action.type === CREATE_SNACKBAR;
}

export function isCloseSnackbarAction(action: AnyAction): action is CloseSnackbar {
  return action.type === CLOSE_SNACKBAR;
}

export function isCloseAllSnackbarsAction(action: AnyAction): action is CloseAllSnackbars {
  return action.type === CLOSE_ALL_SNACKBARS;
}

export function isDisplaySnackbarAction(action: AnyAction): action is DisplaySnackbar {
  return action.type === DISPLAY_SNACKBAR;
}

export function isRemoveSnackbarAction(action: AnyAction): action is RemoveSnackbar {
  return action.type === REMOVE_SNACKBAR;
}
