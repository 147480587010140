import {DateTime} from 'luxon';
import {always, assoc, compose, evolve, groupBy, ifElse, indexBy, isNil, map, mapObjIndexed, omit, prop} from 'ramda';
import {AnyAction, combineReducers} from 'redux';
import {
  HandheldDto,
  isGetHandheldCountSuccessAction,
  isGetHandheldsAction,
  isGetHandheldsSuccessAction
} from './actions';
import {Handheld, Handhelds} from './state';

const initialState: Handhelds = {
  allSerialNumbers: [],
  byCustomerId: {},
  bySerialNumber: {},
  countsByCustomerId: {},
  hasLoaded: false
};

export const handhelds = combineReducers({
  allSerialNumbers,
  byCustomerId,
  bySerialNumber,
  countsByCustomerId,
  hasLoaded
});

function allSerialNumbers(
    state = initialState.allSerialNumbers,
    action: AnyAction
): Handhelds['allSerialNumbers'] {
  if (isGetHandheldsSuccessAction(action)) {
    return action.payload.data.map(handheld => handheld.serialNumber);
  }

  return state;
}

const addLuxon = evolve({
  lastConnectionAt: ifElse(isNil, always(null), compose(DateTime.fromMillis, Date.parse))
});
const setLocationCode = (handheldDto: HandheldDto) => assoc('locationCode', handheldDto.location.code, handheldDto);

const stripLocation:
    // This type declaration is to handhold to type checker through the composition
    (_: HandheldDto & {locationCode: string}) => Omit<HandheldDto, 'location'> & {locationCode: string} =
    omit(['location']);
const indexBySerialNumber = indexBy<Handheld>(prop('serialNumber'));
const parseHandhelds = compose(indexBySerialNumber, map(compose(addLuxon, stripLocation, setLocationCode)));

function bySerialNumber(state = initialState.bySerialNumber, action: AnyAction): Handhelds['bySerialNumber'] {
  if (isGetHandheldsSuccessAction(action)) {
    return parseHandhelds(action.payload.data);
  }

  return state;
}

const groupByCustomerId = groupBy<HandheldDto>(prop('customerId'));
const indexedHubsToSerialNumbers = mapObjIndexed((customerHandhelds: HandheldDto[]) => customerHandhelds.map(prop('serialNumber')));
const handheldSerialNumbersByCustomerId = compose(indexedHubsToSerialNumbers, groupByCustomerId);

function byCustomerId(state = initialState.byCustomerId, action: AnyAction): Handhelds['byCustomerId'] {
  if (isGetHandheldsSuccessAction(action)) {
    return handheldSerialNumbersByCustomerId(action.payload.data);
  }

  return state;
}

function countsByCustomerId(state = initialState.countsByCustomerId, action: AnyAction) {
  if (isGetHandheldCountSuccessAction(action)) {
    return assoc(action.meta.previousAction.customerId, action.payload.data, state);
  }

  return state;
}

function hasLoaded(state = initialState.hasLoaded, action: AnyAction) {
  if (isGetHandheldsSuccessAction(action)) {
    return true;
  } else if (isGetHandheldsAction(action)) {
    return false;
  }

  return state;
}
