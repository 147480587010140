import Keycloak from 'keycloak-js';
import config from './config';

const keycloakConfig = {
  clientId: config.auth.clientId,
  realm: config.auth.realm,
  url: config.auth.url
};

export const keycloak = Keycloak(keycloakConfig);

export function hasKeycloakRole(role: string) {
  return keycloak.hasRealmRole(role);
}
