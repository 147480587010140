import {AnyAction} from 'redux';
import {AxiosAction, AxiosSuccessAction} from '../../middleware/axios';

const GET_RING_RELEASES = 'RING_RELEASES.GET_ALL';
const GET_RING_RELEASES_SUCCESS = GET_RING_RELEASES + '_SUCCESS';

export interface RingReleaseDto {
  versionCode: number;
  versionName: string;
  name: string;
}

export interface GetRingReleasesAction extends AxiosAction<RingReleaseDto[]> {
  type: typeof GET_RING_RELEASES;
}

export interface GetRingReleasesSuccessAction extends AxiosSuccessAction<RingReleaseDto[]> {
  type: typeof GET_RING_RELEASES_SUCCESS;
}

export function getRingReleases(): GetRingReleasesAction {
  return {
    payload: {
      client: 'updateServer',
      request: {
        url: '/rings/releases'
      }
    },
    type: GET_RING_RELEASES
  };
}

export function isGetRingReleasesSuccessAction(action: AnyAction): action is GetRingReleasesSuccessAction {
  return action.type === GET_RING_RELEASES_SUCCESS;
}
