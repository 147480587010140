import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { ReactElement, ReactNode, useState } from 'react';

export interface Props {
  elements?: TextFieldProps[];
  heading: string;
  secondaryHeading?: string;
  name: string;
  initialExpanded: boolean;
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    flexBasis: '60%',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15)
  },
  textField: {
    margin: theme.spacing(0)
  },
  toolBar: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
    justifyContent: 'flex-end'
  }
}));

export const FormPanel = (props: Props) => {
  const { elements, heading, secondaryHeading, name, children, initialExpanded } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(initialExpanded);
  const onCollapsePanelChange = (event: any, isExpanded: boolean) => setExpanded(isExpanded);

  const CollapsibleTextField = (fieldProps: TextFieldProps): ReactElement => {
    const { margin, className, InputProps, ...rest } = fieldProps;

    return (
      <AccordionDetails>
        <FormControl className={className} fullWidth={true}>
          <TextField
            margin="normal"
            className={classes.textField}
            InputProps={InputProps || { readOnly: true }}
            {...rest}
          />
        </FormControl>
      </AccordionDetails>
    );
  };

  return (
    <Accordion onChange={onCollapsePanelChange} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={name}
        id={name}
      >
        <Typography className={classes.heading}>{heading}</Typography>
        {
          expanded ? null : (<Typography className={classes.secondaryHeading}>{secondaryHeading}</Typography>)
        }
      </AccordionSummary>
      {
        elements && elements.map(elementProps => (
          <CollapsibleTextField key={elementProps.id} {...elementProps} />
        ))
      }
      {React.Children.map(children, (child, index) =>
        child ? <AccordionDetails key={index}>{child}</AccordionDetails> : null)}
    </Accordion>
  );
};
