import {defineMessages} from 'react-intl.macro';

export default defineMessages({
  'hubs.actions': {id: 'hubs.actions', defaultMessage: 'Actions'},
  'hubs.bluetoothMacAddress': {id: 'hubs.bluetoothMacAddress', defaultMessage: 'Bluetooth MAC Address'},
  'hubs.create': {id: 'hubs.create', defaultMessage: 'Add new hub'},
  'hubs.create.error': {id: 'hubs.create.error', defaultMessage: 'Hub Creation Failed'},
  'hubs.create.success': {id: 'hubs.create.success', defaultMessage: 'Hub Created Successfully'},
  'hubs.customer': {id: 'hubs.customer', defaultMessage: 'Customer'},
  'hubs.customer.required': {id: 'hubs.customer.required', defaultMessage: 'Customer is required'},
  'hubs.customer.select': {id: 'hubs.customer.select', defaultMessage: 'Please select a customer'},
  'hubs.edit': {id: 'hubs.edit', defaultMessage: 'Edit Hub'},
  'hubs.ethernetMacAddress': {id: 'hubs.ethernetMacAddress', defaultMessage: 'Ethernet MAC Address'},
  'hubs.hub': {id: 'hubs.hub', defaultMessage: 'Hub'},
  'hubs.macAddresses': {id: 'hubs.macAddresses', defaultMessage: 'MAC Addresses'},
  'hubs.name': {id: 'hubs.name', defaultMessage: 'Name'},
  'hubs.name.maxLength': {id: 'hubs.name.maxLength', defaultMessage: 'Name can be at most {maxLength} characters long'},
  'hubs.name.nonBlank': {id: 'hubs.name.nonBlank', defaultMessage: 'Name must not be blank'},
  'hubs.name.required': {id: 'hubs.name.required', defaultMessage: 'Name is required'},
  'hubs.serialNumber': {id: 'hubs.serialNumber', defaultMessage: 'Serial Number'},
  'hubs.serialNumber.pattern': {id: 'hubs.serialNumber.pattern', defaultMessage: 'Serial Number is invalid'},
  'hubs.serialNumber.required': {id: 'hubs.serialNumber.required', defaultMessage: 'Serial Number is required'},
  'hubs.title': {id: 'hubs.title', defaultMessage: 'Hubs'},
  'hubs.update': {id: 'hubs.update', defaultMessage: 'View'},
  'hubs.update.error': {id: 'hubs.update.error', defaultMessage: 'Hub Update Failed'},
  'hubs.update.success': {id: 'hubs.update.success', defaultMessage: 'Hub Updated Successfully'},
  'hubs.validMacAddress': {id: 'hubs.validMacAddress', defaultMessage: 'Must be a valid MAC address'},
  'hubs.wifiMacAddress': {id: 'hubs.wifiMacAddress', defaultMessage: 'WiFi MAC Address'},
  'hubs.zigbeeMacAddress': {id: 'hubs.zigbeeMacAddress', defaultMessage: 'Zigbee MAC Address'}
});
