export enum Module {
  AUTOMATED_MONITORING = 'AUTOMATED-MONITORING',
  WORK_MANAGEMENT = 'WORK-MANAGEMENT'
}

export enum CustomerType {
  DORMANT = 'dormant',
  INTERNAL = 'internal',
  LIVE = 'live',
  TRIAL = 'trial'
}

export interface Customer {
  customerId: string;
  features?: string[];
  settings?: string[];
  isTemplate: boolean;
  modules: Module[];
  name: string;
  type: CustomerType;
}

export interface CustomersState {
  allIds: string[];
  byId: {
    [key: string]: Customer
  };
}
