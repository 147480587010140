import {path} from 'ramda';
import {AnyAction, Dispatch, Middleware, MiddlewareAPI} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

export const axiosResponseCallback: Middleware =
  (store: MiddlewareAPI<ThunkDispatch<{}, {}, AnyAction>>) =>
    (next: Dispatch) =>
      (action: AnyAction) => {
        if (isSuccessAction(action) && hasPreviousActionSuccessCallback(action)) {
          store.dispatch(action.meta.previousAction.onSuccess(action.payload.data));
        }
        next(action);
      };

const hasPreviousActionSuccessCallback = (action: AnyAction) => !!path(['meta', 'previousAction', 'onSuccess'], action);
const isSuccessAction = (action: AnyAction) => action.type && action.type.indexOf('_SUCCESS') > -1;
