import {AnyAction} from 'redux';
import {AxiosAction, AxiosSuccessAction} from '../middleware/axios';
import { LocationSettingsInput } from './state';

const GET_ROOT_LOCATION = 'LOCATION.ROOT.GET';
const GET_DESCENDANTS = 'LOCATION.GET_DESCENDANTS';
const GET_ROOT_LOCATION_SUCCESS = GET_ROOT_LOCATION + '_SUCCESS';
const GET_DESCENDANTS_SUCCESS = GET_DESCENDANTS + '_SUCCESS';

const PUT_LOCATION_SETTING = 'LOCATION.SETTING.PUT';
const PUT_LOCATION_SETTING_SUCCESS = PUT_LOCATION_SETTING + '_SUCCESS';
const DELETE_LOCATION_SETTING = 'LOCATION.SETTING.DELETE';
const DELETE_LOCATION_SETTING_SUCCESS = DELETE_LOCATION_SETTING + '_SUCCESS';

export interface LocationDto {
  code: string;
  name: string;
  created: string;
  updated: string;
  localId: string;
  version: number;
  parentCode: string | null;
}

export type DescendantDto = LocationDto & { parentCode: string };

export interface GetRootLocationAction extends AxiosAction {
  customerId: string;
  type: typeof GET_ROOT_LOCATION;
}

export interface GetDescendantsAction extends AxiosAction {
  locationCode: string;
  type: typeof GET_DESCENDANTS;
}

export interface GetRootLocationSuccessAction extends AxiosSuccessAction<LocationDto, GetRootLocationAction> {
  type: typeof GET_ROOT_LOCATION_SUCCESS;
}

export interface GetDescendantsSuccessAction extends AxiosSuccessAction<DescendantDto[], GetDescendantsAction> {
  type: typeof GET_DESCENDANTS_SUCCESS;
}

export interface PutLocationSettingAction extends AxiosAction {
  type: typeof PUT_LOCATION_SETTING;
}

export interface PutLocationSettingSuccessAction extends AxiosAction {
  type: typeof PUT_LOCATION_SETTING_SUCCESS;
}

export interface DeleteLocationSettingAction extends AxiosAction {
  type: typeof DELETE_LOCATION_SETTING;
}

export interface DELETELocationSettingSuccessAction extends AxiosAction {
  type: typeof DELETE_LOCATION_SETTING_SUCCESS;
}

export function getRootLocation(customerId: string): GetRootLocationAction {
  return {
    customerId,
    payload: {
      client: 'core',
      request: {
        method: 'GET',
        url: `/locations/${customerId}/root`
      }
    },
    type: GET_ROOT_LOCATION
  };
}

export function getDescendants(locationCode: string): GetDescendantsAction {
  return {
    locationCode,
    payload: {
      client: 'core',
      request: {
        method: 'GET',
        url: `/locations/${locationCode}/descendants`
      }
    },
    type: GET_DESCENDANTS
  };
}

export function putLocationSetting(
  locationSetting: LocationSettingsInput
): PutLocationSettingAction {
  return {
    payload: {
      client: 'customerProfile',
      request: {
        data: {value : locationSetting.value},
        method: 'PUT',
        url: `/locations/${locationSetting.locationCode}/features/${locationSetting.featureCode}/settings/${locationSetting.settingCode}`
      }
    },
    type: PUT_LOCATION_SETTING
  };
}

export function deleteLocationSetting(
  locationSetting: Omit<LocationSettingsInput, 'value'>
): DeleteLocationSettingAction {
  return {
    payload: {
      client: 'customerProfile',
      request: {
        method: 'DELETE',
        url: `/locations/${locationSetting.locationCode}/features/${locationSetting.featureCode}/settings/${locationSetting.settingCode}`
      }
    },
    type: DELETE_LOCATION_SETTING
  };
}

export function isGetRootLocation(action: AnyAction): action is GetRootLocationAction {
  return action.type === GET_ROOT_LOCATION;
}

export function isGetRootLocationSuccess(action: AnyAction): action is GetRootLocationSuccessAction {
  return action.type === GET_ROOT_LOCATION_SUCCESS;
}

export function isGetDescendants(action: AnyAction): action is GetDescendantsAction {
  return action.type === GET_DESCENDANTS;
}

export function isGetDescendantsSuccess(action: AnyAction): action is GetDescendantsSuccessAction {
  return action.type === GET_DESCENDANTS_SUCCESS;
}

export function isPutLocationSettingSuccess(action: AnyAction): action is PutLocationSettingSuccessAction {
  return action.type === PUT_LOCATION_SETTING_SUCCESS;
}

export function isDeleteLocationSettingSuccess(action: AnyAction): action is DELETELocationSettingSuccessAction {
  return action.type === DELETE_LOCATION_SETTING_SUCCESS;
}
