import {AutocompleteOption, SingleAutocomplete} from '@checkit/react-components';
import {prop, sortBy} from 'ramda';
import React, {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {SaveToolbar} from '../../components/saveToolbar/SaveToolbar';
import {AxiosDispatch} from '../../middleware/axios';
import {State} from '../../state';
import {CustomersState} from '../state';
import messages from '../translations';

interface OwnProps {
  customerId: string;
  formSubmitting: boolean;
  newChildren: string[];
  onAddChild: (newChild: string) => void;
  onSave: () => Promise<void>;
}

export interface Props extends OwnProps {
  customersById: CustomersState['byId'];
  children: string[];
  isParent: boolean;
}

const sortByName = sortBy(prop('name'));

export const AddChildForm = (props: Props) => {
  const { customersById, children, customerId, newChildren, onAddChild } = props;
  const { formatMessage } = useIntl();

  const getOptions = useCallback(async (text: string, limit: number): Promise<AutocompleteOption[]> => {
    const searchString = text.toLowerCase();
    // Cannot add children we already have, or the customer itself
    // TODO we should probably exclude other parents here as well
    // TODO use native autocomplete blacklist when available
    const blacklist = [customerId, ...children, ...newChildren];

    const customers = Object.values(customersById);
    const matchingCustomers = customers.filter(c =>
      !blacklist.includes(c.customerId) && c.name.toLowerCase().includes(searchString)
    );

    const sortedCustomers = sortByName(matchingCustomers);

    return sortedCustomers.slice(0, limit).map(c => ({
      code: c.customerId,
      name: c.name
    }));
  }, [ children, customersById, customerId, newChildren ]);

  const onChange = useCallback((_: React.ChangeEvent<{}>, value: AutocompleteOption | null) => {
    if (value == null) {
      return;
    }

    onAddChild(value.code);
  }, [ onAddChild ]);

  return (
    <>
      <SingleAutocomplete
        className="add-child-autocomplete"
        onChange={onChange}
        getOptions={getOptions}
        clearOnSelect={true}
        label={formatMessage(messages['customers.addChildCustomer'])}
      />
      <SaveToolbar
        formSubmitting={props.formSubmitting}
        saveId={'add-child-save'}
        onSave={props.onSave}
      />
    </>
  );
};

const mapStateToProps = (state: State, props: OwnProps) => ({
  children: state.customerRelationship.children[props.customerId] || [],
  customersById: state.customers.byId,
  isParent: state.customerFeatures.features[props.customerId] &&
    state.customerFeatures.features[props.customerId].includes('LIBRARIES')
});

const mapDispatchToProps = (dispatch: AxiosDispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(AddChildForm);
