import { compose, groupBy, indexBy, map, mapObjIndexed, mergeLeft, omit, prop, union } from 'ramda';
import { AnyAction, combineReducers } from 'redux';
import { isGetHandheldsSuccessAction } from '../handhelds/actions';
import { DescendantDto, isGetDescendantsSuccess, isGetRootLocationSuccess } from './actions';
import { Address, Location, Locations } from './state';

const initialState: Locations = {
  addressesByCode: {},
  allCodes: [],
  byCode: {},
  children: {},
  rootLocations: {}
};

export const locations = combineReducers({
  addressesByCode,
  allCodes,
  byCode,
  children,
  rootLocations
});

function allCodes(state = initialState.allCodes, action: AnyAction): Locations['allCodes'] {
  if (isGetHandheldsSuccessAction(action)) {
    return union(state, action.payload.data.map(handheld => handheld.location.code));
  }

  return state;
}

const extractLocation = prop<'location', Location>('location');
const stripAddress = omit(['address']);
const indexByCode = indexBy<Location>(prop('code'));
const parseLocations = compose(indexByCode, map(compose(stripAddress, extractLocation)));

function byCode(state = initialState.byCode, action: AnyAction): Locations['byCode'] {
  if (isGetHandheldsSuccessAction(action)) {
    const parsedLocations = parseLocations(action.payload.data);
    return mergeLeft(state, parsedLocations);
  } else if (isGetRootLocationSuccess(action)) {
    return {
      ...state,
      [action.payload.data.code]: action.payload.data
    };
  } else if (isGetDescendantsSuccess(action)) {
    return {
      ...state,
      ...indexByCode(action.payload.data)
    };
  }

  return state;
}

const extractAddress = prop('address');
const parseAddresses = compose(mapObjIndexed(extractAddress), indexByCode, map(extractLocation)) ;

function addressesByCode(state = initialState.addressesByCode, action: AnyAction): Locations['addressesByCode'] {
  if (isGetHandheldsSuccessAction(action)) {
    // Note: Ramda compose is not fully typescript typed
    return mergeLeft(state, parseAddresses(action.payload.data) as Record<string, Address>);
  }

  return state;
}

const groupByParent = groupBy<DescendantDto>(prop('parentCode'));

function children(state = initialState.children, action: AnyAction) {
  if (isGetDescendantsSuccess(action)) {
    const locationsByParent = groupByParent(action.payload.data);

    // Make sure we have an entry for the location we asked for (which we won't if it has no descendants)
    if (!locationsByParent[action.meta.previousAction.locationCode]) {
      locationsByParent[action.meta.previousAction.locationCode] = [];
    }

    // The result of this action is complete for the requested location
    return {
      ...state,
      ...mapObjIndexed(map(prop('code')), locationsByParent)
    };
  }

  return state;
}

function rootLocations(state = initialState.rootLocations, action: AnyAction) {
  if (isGetRootLocationSuccess(action)) {
    return {
      ...state,
      [action.meta.previousAction.customerId]: action.payload.data.code
    };
  }

  return state;
}
