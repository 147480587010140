import { FormHelperText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { CheckboxProps } from '@material-ui/core/Checkbox/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectProps } from '@material-ui/core/Select';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/styles/makeStyles';
import { union, without } from 'ramda';
import React, { ChangeEvent, createRef, ReactNode, RefObject, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { FormPanel } from '../../components/form-panel/FormPanel';
import { SaveToolbar } from '../../components/saveToolbar/SaveToolbar';
import { State } from '../../state';
import CustomerTemplateSelect, { DEFAULT_TEMPLATE_ID } from '../customer-templates/CustomerTemplateSelect';
import { Customer, CustomerType, Module } from '../state';
import messages from '../translations';

export interface SubmitProps {
  isTemplate: boolean;
  modules: Module[];
  name: string;
  customerTemplateId: string;
  type: CustomerType;
  userName: string;
  email: string;
}

interface OwnProps {
  customerId: string | null;
  onSubmit: (data: SubmitProps) => Promise<AnyAction>;
  children?: ReactNode;
}

export interface Props extends OwnProps {
  customer: Customer | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  formGroup: {
    width: '100%'
  },
  selectFormControl: {
    width: '100%'
  }
}));

const MAX_NAME_LENGTH = 100;

export const CustomerForm = (props: Props) => {
  const { customer, customerId } = props;
  const isNewCustomer = customerId === null;

  const formRef = createRef() as RefObject<ValidatorForm>;

  const i18n = useIntl();

  const [name, setName] = useState('');
  const [isTemplate, setIsTemplate] = useState(false);
  const [modules, setModules] = useState<Module[]>([]);
  const [type, setType] = useState(CustomerType.LIVE);
  const [customerTemplateId, setCustomerTemplateId] = useState<string>(isNewCustomer ? DEFAULT_TEMPLATE_ID : '');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [formSubmitting, setFormSubmitting] = useState(false);

  useEffect(() => {
    if (customer) {
      setName(customer.name);
      setIsTemplate(customer.isTemplate);
      setModules(customer.modules);
      setType(customer.type);
    }
  }, [customer]);

  const onChangeName = (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value);
  const onChangeUserName = (event: ChangeEvent<HTMLInputElement>) => setUserName(event.target.value);
  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
  const onChangeIsTemplate: CheckboxProps['onChange'] = (event, checked) => setIsTemplate(checked);
  const onChangeType: SelectProps['onChange'] = (event) => setType(event.target.value as CustomerType);
  const onChangeTemplateCustomerId: SelectProps['onChange'] = (event) => {
    const newValue = event.target.value;
    setCustomerTemplateId(newValue as string);
  };

  const onChangeModule: (module: Module) => CheckboxProps['onChange'] = (module: Module) => (event, checked) => {
    if (checked) {
      setModules(union(modules, [module]));
    } else {
      setModules(without([module], modules));
    }
  };

  const onSubmit = async (event: any) => {
    let isFormValid = false;

    event.preventDefault();

    // Form validation
    if (formRef.current) {
      isFormValid = await formRef.current.isFormValid(false);
    }

    if (isFormValid) {
      setFormSubmitting(true);

      const resultingAction = await props.onSubmit({
        customerTemplateId,
        email,
        isTemplate,
        modules,
        name,
        type,
        userName
      });

      // If the action was successful, the component won't be mounted and we get a warning
      if (resultingAction.type.indexOf('_SUCCESS') === -1) {
        setFormSubmitting(false);
      }
    }
  };

  const classes = useStyles();
  const { formatMessage } = useIntl();

  const modulesNotSelected = modules.length === 0;

  return (
    <ValidatorForm ref={formRef} onSubmit={onSubmit}>
      {/*TODO having the FormPanel inside the form erroneously gives it rounded bottom corners*/}
      <FormPanel
        heading={formatMessage(messages['customers.customer'])}
        secondaryHeading={name}
        name="customer"
        initialExpanded={true}
      >
        {isNewCustomer ? (
          <FormGroup row={true} className={classes.formGroup}>
            <CustomerTemplateSelect
              className={classes.selectFormControl}
              onChange={onChangeTemplateCustomerId}
              selectedCustomerId={customerTemplateId}
              newCustomer={true}
            />
          </FormGroup>
        )
          : null
        }
        <FormGroup className={classes.formGroup}>
          <TextValidator
            fullWidth={true}
            id="name"
            label={i18n.formatMessage(messages['customers.name'])}
            name="name"
            autoFocus={true}
            value={name}
            withRequiredValidator={true}
            validators={['required', 'trim', `maxStringLength:${MAX_NAME_LENGTH}`]}
            errorMessages={[
              i18n.formatMessage(messages['customers.name.required']),
              i18n.formatMessage(messages['customers.name.nonBlank']),
              i18n.formatMessage(messages['customers.name.maxLength'], { maxLength: MAX_NAME_LENGTH })
            ]}
            onChange={onChangeName}
          />
        </FormGroup>
        <FormGroup row={true} className={classes.formGroup}>
          <FormControl className={classes.selectFormControl}>
            <InputLabel htmlFor="type-select">{i18n.formatMessage(messages['customers.type'])}</InputLabel>
            <Select
              value={type}
              inputProps={{
                id: 'type-select',
                name: 'type'
              }}
              onChange={onChangeType}
            >
              {
                Object.values(CustomerType).map(customerType => (
                  <MenuItem key={customerType} value={customerType}>
                    {i18n.formatMessage(messages[customerType])}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </FormGroup>
        {isNewCustomer ? (
          <FormGroup className={classes.formGroup}>
            <TextValidator
              fullWidth={true}
              id="userName"
              label={i18n.formatMessage(messages['customers.firstUserName'])}
              name="userName"
              value={userName}
              withRequiredValidator={true}
              validators={['required', 'trim']}
              errorMessages={[
                i18n.formatMessage(messages['customers.firstUserName.required']),
                i18n.formatMessage(messages['customers.firstUserName.nonBlank'])
              ]}
              onChange={onChangeUserName}
            />
            <TextValidator
              fullWidth={true}
              id="email"
              label={i18n.formatMessage(messages['customers.firstUserEmail'])}
              name="email"
              value={email}
              withRequiredValidator={true}
              validators={['required', 'isEmail']}
              errorMessages={[
                i18n.formatMessage(messages['customers.firstUserEmail.required']),
                i18n.formatMessage(messages['customers.firstUserEmail.validEmail'])
              ]}
              onChange={onChangeEmail}
            />
          </FormGroup>
        ) : null
        }
        <FormControl error={modulesNotSelected} component="fieldset" fullWidth={true}>
          {
            Object.values(Module).map(module => (
              <FormControlLabel
                key={module}
                control={(
                  <Checkbox
                    color="primary"
                    checked={modules.includes(module)}
                    name="modules"
                    onChange={onChangeModule(module)}
                    required={modules.length === 0}
                    value={isTemplate}
                  />
                )}
                label={i18n.formatMessage(messages[module + '.name'])}
              />
            ))
          }
          {modulesNotSelected ? (
            <FormHelperText>
              {i18n.formatMessage(messages['customers.modules.required'])}
            </FormHelperText>
          ) : null}
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox color="primary" checked={isTemplate} onChange={onChangeIsTemplate} value={isTemplate} />
          }
          label={i18n.formatMessage(messages['customers.isTemplate'])}
        />

        {!isNewCustomer ? (
          <FormGroup row={true} className={classes.formGroup}>
            <CustomerTemplateSelect
              className={classes.selectFormControl}
              onChange={onChangeTemplateCustomerId}
              selectedCustomerId={customerTemplateId}
              newCustomer={false}
            />
          </FormGroup>
        ) : null
        }
        <SaveToolbar formSubmitting={formSubmitting} />
      </FormPanel>
    </ValidatorForm>
  );
};

const mapStateToProps = (state: State, props: OwnProps) => ({
  customer: props.customerId ? state.customers.byId[props.customerId] : null
});

export default connect(mapStateToProps)(CustomerForm);
