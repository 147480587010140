import { AnyAction } from 'redux';
import {AxiosAction, AxiosSuccessAction} from '../middleware/axios';

const GET_ME = 'ME.GET';
const GET_ME_SUCCESS = GET_ME + '_SUCCESS';

interface Response {
  email: string;
  name: string;
}

export interface GetMeSuccessAction extends AxiosSuccessAction<Response> {
  type: typeof GET_ME_SUCCESS;
}

export interface GetMeAction extends AxiosAction {
  type: typeof GET_ME;
}

export function getMe(): GetMeAction {
  return {
    payload: {
      client: 'backend',
      request: {
        method: 'GET',
        url: '/users/me'
      }
    },
    type: GET_ME
  };
}

export function isGetMeSuccess(action: AnyAction): action is GetMeSuccessAction {
  return action.type === GET_ME_SUCCESS;
}
