import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  'locations.action': {
    defaultMessage: 'Action',
    id: 'locations.action'
  },
  'locations.cancelTooltipText': {
    defaultMessage: 'Cancel',
    id: 'locations.cancelTooltipText'
  },
  'locations.clickEditToSetValue': {
    defaultMessage: "Click 'Edit' to set value",
    id: 'locations.clickEditToSetValue'
  },
  'locations.confirmRemoveSetting': {
    defaultMessage: 'Are you sure to remove the location setting?',
    id: 'locations.confirmRemoveSetting'
  },
  'locations.confirmUpdateSetting': {
    defaultMessage: 'Are you sure to update the location setting?',
    id: 'locations.confirmUpdateSetting'
  },
  'locations.customerValue': {
    defaultMessage: 'Customer Value',
    id: 'locations.customerValue'
  },
  'locations.defaultValue': {
    defaultMessage: 'Default Value',
    id: 'locations.defaultValue'
  },
  'locations.deleteTooltipText': {
    defaultMessage: 'Reset location value',
    id: 'locations.deleteTooltipText'
  },
  'locations.description': {
    defaultMessage: 'Description',
    id: 'locations.description'
  },
  'locations.editTooltipText': {
    defaultMessage: 'Edit location value',
    id: 'locations.editTooltipText'
  },
  'locations.errorMessage': {
    defaultMessage: 'Location has no setting or feature assigned',
    id: 'SETTING_CODE_OR_FEATURE_CODE_NOT_ASSOCIATED_TO_LOCATION'
  },
  'locations.feature': {
    defaultMessage: 'Feature',
    id: 'locations.feature'
  },
  'locations.featureCodeNotFound': {
    defaultMessage: 'Cannot find featureCode',
    id: 'locations.featureCodeNotFound'
  },
  'locations.invalidLocationValue': {
    defaultMessage: 'Invalid location value',
    id: 'locations.invalidLocationValue'
  },
  'locations.locationValue': {
    defaultMessage: 'Location Value',
    id: 'locations.locationValue'
  },
  'locations.name': {
    defaultMessage: 'Name',
    id: 'locations.name'
  },
  'locations.notEnabled': {
    defaultMessage: 'Not Enabled',
    id: 'locations.notEnabled'
  },
  'locations.rowNotFound': {
    defaultMessage: 'Row not found',
    id: 'locations.rowNotFound'
  },
  'locations.saveTooltipText': {
    defaultMessage: 'Save location value',
    id: 'locations.saveTooltipText'
  },
  'locations.selectLocation': {
    defaultMessage: 'Select Location',
    id: 'locations.selectLocation'
  },
  'locations.selectedLocation': {
    defaultMessage: 'Selected Location: {locationName}',
    id: 'locations.selectedLocation'
  },
  'locations.setByClickingEdit': {
    defaultMessage: "Set by clicking 'Edit'",
    id: 'locations.setByClickingEdit'
  },
  'locations.settingCodeNotPresent': {
    defaultMessage: 'Setting code not present',
    id: 'locations.settingCodeNotPresent'
  },
  'locations.settingFailed': {
    defaultMessage: 'Location setting Failed',
    id: 'locations.settingFailed'
  },
  'locations.settingNotFound': {
    defaultMessage: 'Cannot find the setting from the settingCode',
    id: 'locations.settingNotFound'
  },
  'locations.settingRemoveConfirm': {
    defaultMessage: 'Location Setting Remove Confirm',
    id: 'locations.settingRemoveConfirm'
  },
  'locations.settingUpdateConfirm': {
    defaultMessage: 'Location Setting Update Confirm',
    id: 'locations.settingUpdateConfirm'
  },
  'locations.settingUpdatedSuccessfully': {
    defaultMessage: 'Location setting updated successfully',
    id: 'locations.settingUpdatedSuccessfully'
  },
  'locations.settings': {
    defaultMessage: 'Settings',
    id: 'locations.settings'
  },
  'locations.title': {
    defaultMessage: 'Locations',
    id: 'locations.title'
  }
});
