import { AnyAction } from 'redux';
import { isGetMeSuccess } from './actions';
import { User } from './state';

const initialState = null;

export function user(state: User | null = initialState, action: AnyAction): User | null {
  if (isGetMeSuccess(action)) {
    const {email, name} = action.payload.data;
    return {
      email,
      name
    };
  }

  return state;
}
