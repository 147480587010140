import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { filter } from 'ramda';
import {
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import AlertDialog from '../../components/alert/AlertDialog';
import config from '../../config';
import {
  addCustomerFeature,
  getCustomerFeatures,
  isAddCustomerFeatureSuccessAction
} from '../../customer-features/actions';
import {
  getCustomer,
  isGetCustomerSuccess
} from '../../customers/actions';
import { Customer } from '../../customers/state';
import messages from '../../customers/translations';
import { hasKeycloakRole } from '../../keycloak';
import { AxiosDispatch } from '../../middleware/axios';
import { State } from '../../state';
import { Feature, SettingWithFeatureCode } from '../state';

export type SettingValueLookup = (settingCode: string) => string | null;
export interface SettingColumn {
  value: SettingValueLookup;
  title: string;
}

interface OwnProps {
  customerId: string;
  enabled: boolean;
  featureCode: string;
  settingColumns: SettingColumn[];
}
export interface Props extends OwnProps {
  // potentially undefined if the customer hasn't been fetched when this page is opened
  customer: Customer | undefined;
  feature: Feature;
  settings: Record<string, SettingWithFeatureCode>;
  dispatch: AxiosDispatch;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  /* tslint:disable:object-literal-sort-keys */

  // avoids making it seem like the entire row is clickable to expand, when just the button is
  container: { cursor: 'auto !important' },
  heading: {
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },

  buttonSpinner: { position: 'absolute' },

  featureName: { flexGrow: 1 },
  featureDescription: {
    // font style
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    // spacing
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    // avoid overflowing
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  // avoid button getting squished by long descriptions
  featureAction: { flexShrink: 0, minWidth: '125px' }

  /* tslint:enable:object-literal-sort-keys */
}));

const customersFetched = new Set<string>( );
/**
 * Issues a request to fetch the customer with the given id, but avoids issuing multiple
 * duplicate requests if this function gets called for the same customer id from multiple
 * components.
 * This is needed as the FeatureComponent is shown in a list along with a bunch of the
 * same component, which will all want to fetch the same customer at the same time if
 * needed.
 */
function fetchCustomer(id: string, dispatch: AxiosDispatch) {
  if (customersFetched.has(id)) {
    // customer is/has already been fetched, don't initiate another request
    return;
  }
  dispatch<AnyAction>(getCustomer(id)).then(function handleGetCustomerResult(result) {
    if (!isGetCustomerSuccess(result)) {
      // throw an error which can be handled by the .catch() block, same as
      // if it was thrown by the dispatch itself
      throw new Error(`Unexpected resulting action when getting customer ${id}: "${result.type}"`);
    }
  }).catch(function handleGetCustomerError(/* err */) {
    // log the error somewhere?
    customersFetched.delete(id);
  });
  customersFetched.add(id);
}

export const FeatureComponent = ({
  customer,
  customerId,
  feature,
  enabled,
  settings,
  settingColumns,
  dispatch
}: Props) => {
  const { formatMessage } = useIntl();
  const theme = useTheme( );
  const classes = useStyles( );

  /** Whether the settings container has been expanded. */
  const [ isExpanded, setIsExpanded ] = useState(false);
  /** Whether the feature is currently in the process of being enabled. */
  const [ isEnabling, setIsEnabling ] = useState(false);
  /** Whether the user is being prompted for confirmation to enable the feature. */
  const [ isConfirmingEnable, setIsConfirmingEnable ] = useState(false);
  /** Whether the customer for whom this feature is being potentially enabled has been fetched. */
  const [ isCustomerFetched, setIsCustomerFetched ] = useState(customer != null);

  useEffect(function fetchCustomerIfNecessary( ) {
    if (!isCustomerFetched) {
      fetchCustomer(customerId, dispatch);
      setIsCustomerFetched(true);
    }
  }, [ isCustomerFetched, customerId, dispatch ]);

  const onClickExpandSettings = useCallback(( ) => setIsExpanded(!isExpanded), [ isExpanded ]);
  const onClickEnableFeature = useCallback(( ) => setIsConfirmingEnable(true), [ ]);
  const onCancelEnable = useCallback(( ) => setIsConfirmingEnable(false), [ ]);
  /** Issues a request to enable this feature for this customer. */
  const onConfirmEnable = useCallback(function doEnable( ) {
    setIsEnabling(true);
    setIsConfirmingEnable(false);
    const enable = addCustomerFeature(customerId, feature.code);
    dispatch<AnyAction>(enable).then(function onceFeatureAdded(result) {
      if (isAddCustomerFeatureSuccessAction(result)) {
        dispatch(getCustomerFeatures(customerId));
      } else {
        throw new Error(`Unexpected resulting action type "${result.type}"`);
      }
    });
  }, [ customerId, dispatch, feature.code ]);

  const hasAddFeaturesRole = hasKeycloakRole(config.addFeaturesRole);

  const description = feature.description || formatMessage(messages['customers.feature.noDescription']);

  const columns = settingColumns.concat([{
    title: 'Default Value',
    value: (settingCode) => settings[settingCode].defaultValue
  }]);
  const numSettings = feature.settings ? feature.settings.length : 0;
  const hasSettings = numSettings > 0;

  // sort settings alphabetically based on setting name
  const sortedSettings = feature?.settings?.map(settingCode => settings[settingCode])
      .sort((settingA, settingB) => settingA.name.localeCompare(settingB.name))
      ?? [];

  const action = !enabled && hasAddFeaturesRole ?
  (
    <Button
      variant="contained"
      className={classes.featureAction}
      color="primary"
      onClick={onClickEnableFeature}
      disabled={isEnabling}
    >
      {isEnabling ? <CircularProgress className={classes.buttonSpinner} size={14} /> : null}
      {formatMessage(messages['customers.feature.enable'])}
    </Button>
  ) : (enabled && hasSettings) ?
  (
    <Button
      variant="outlined"
      className={classes.featureAction}
      onClick={onClickExpandSettings}
    >
      {formatMessage(messages['customers.feature.numSettings'], { numSettings })}
    </Button>
  ) : (enabled && !hasSettings) ?
  (
    <Button
      variant="outlined"
      className={classes.featureAction}
      disabled={true}
    >
      {formatMessage(messages['customers.feature.numSettings'], { numSettings } )}
    </Button>
  ) : null;

  const confirmationDialogContents = (
    <>
      <Typography variant="body2">
        {formatMessage(messages['customers.feature.enable.confirm.header'], {
          b: (...chunks: ReactNode[]) => (<b>{chunks}</b>),
          customer: customer ? customer.name : customerId,
          feature: feature.name
        })}
      </Typography>

      <Typography variant="body2">{formatMessage(messages['customers.feature.enable.confirm.body'])}</Typography>
      <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic' }}>{description}</Typography>

      <br />

      <Typography variant="body2">
        {formatMessage(messages['customers.feature.enable.confirm.footer'], {
          b: (...chunks: ReactNode[]) => (<b style={{ color: theme.palette.error.main }}>{chunks}</b>),
          u: (...chunks: ReactNode[]) => (<u key={chunks.join('.')}>{chunks}</u>)
        })}
      </Typography>
    </>
  );

  return (
    <Accordion expanded={isExpanded}>

      <AccordionSummary classes={{ content: classes.heading, root: classes.container }}>
        <Typography className={classes.featureName}>{feature.name}</Typography>
        <Tooltip title={(<>{feature.name + ':'}<br />{description}</>)}>
          <Typography className={classes.featureDescription}>{feature.description}</Typography>
        </Tooltip>
        {action}
      </AccordionSummary>

      {enabled && sortedSettings.length > 0 ? (
        <AccordionDetails>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{formatMessage(messages['customers.setting.name'])}</TableCell>
                {columns.map(column => <TableCell key={column.title}>{column.title}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedSettings.map(setting => (
                <TableRow key={setting.code}>
                  <TableCell>
                    <Tooltip title={setting.description || ''} placement="right">
                      <span>{setting.name}</span>
                    </Tooltip>
                  </TableCell>
                  {columns.map(column => (
                    <TableCell key={[setting.code, column.title].join('.')}>
                      {column.value([setting.featureCode, setting.code].join('.'))}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      ) : null}

      <AlertDialog
        open={isConfirmingEnable}
        title={formatMessage(messages['customers.feature.enable.confirm.title'], { feature: feature.code })}
        text={confirmationDialogContents}
        onCancel={onCancelEnable}
        onAccept={onConfirmEnable}
      />

    </Accordion>
  );
};

const filterByFeature = (featureCode: string, settings: Record<string, SettingWithFeatureCode>) =>
  filter(s => s.featureCode === featureCode, settings);

const mapStateToProps = (state: State, props: OwnProps) => ({
  customer: state.customers.byId[props.customerId],
  feature: state.features.byCode[props.featureCode],
  settings: filterByFeature(props.featureCode, state.features.settingsByCode)
});
const mapDispatchToProps = (dispatch: AxiosDispatch) => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(FeatureComponent);
