import {AnyAction} from 'redux';
import {Address, Location} from '../locations/state';
import {AxiosAction, AxiosSuccessAction} from '../middleware/axios';

const UPDATE_HANDHELD = 'HANDHELDS.UPDATE';
const GET_HANDHELDS = 'HANDHELDS.GET_ALL';
const COUNT = 'HANDHELDS.COUNT';
const GET_HANDHELDS_SUCCESS = GET_HANDHELDS + '_SUCCESS';
const UPDATE_HANDHELD_SUCCESS = UPDATE_HANDHELD + '_SUCCESS';
const COUNT_SUCCESS = COUNT + '_SUCCESS';

type LocationWithAddress = Location & { address: Address };

export interface HandheldDto {
  serialNumber: string;
  macAddress: string | null;
  displayName: string;
  isBlocked: boolean;
  // May contain leading zeros
  administrationPin: string;
  customerId: string;
  extra: any | null;
  modelName: string | null;
  appVersion: string | null;
  androidVersion: string | null;
  lastConnectionAt: string | null;
  cognitoIdentityId: string | null;
  location: LocationWithAddress;
}

export interface GetHandheldsAction extends AxiosAction {
  type: typeof GET_HANDHELDS;
}

export interface UpdateHandheldAction extends AxiosAction {
  type: typeof UPDATE_HANDHELD;
}

export interface GetHandheldsSuccessAction extends AxiosSuccessAction<HandheldDto[]> {
  type: typeof GET_HANDHELDS_SUCCESS;
}

export interface UpdateHandheldSuccessAction extends AxiosSuccessAction<HandheldDto[]> {
  type: typeof UPDATE_HANDHELD_SUCCESS;
}

export interface GetHandheldCountAction extends AxiosAction {
  customerId: string;
  type: typeof COUNT;
}

export interface GetHandheldCountSuccessAction extends AxiosSuccessAction<number, GetHandheldCountAction> {
  type: typeof COUNT_SUCCESS;
}

export interface GetHandheldsOptions {
  filter?: string;
  onSuccess?: AxiosAction['onSuccess'];
  skip?: number;
  take: number;
}

export function getHandhelds(
  customerId: string,
  options = {} as GetHandheldsOptions,
  searchText?: string
): GetHandheldsAction {
  return {
    onSuccess: options.onSuccess,
    payload: {
      client: 'workManagement',
      request: {
        params: {
          filter: searchText,
          skip: options.skip,
          take: options.take
        },
        url: `/customers/${customerId}/handhelds`
      }
    },
    type: GET_HANDHELDS
  };
}

export function getHandheldCount(customerId: string): GetHandheldCountAction {
  return {
    customerId,
    payload: {
      client: 'workManagement',
      request: {
        url: `/customers/${customerId}/handhelds/count`
      }
    },
    type: COUNT
  };
}

export function isGetHandheldsAction(action: AnyAction): action is GetHandheldsSuccessAction {
  return action.type === GET_HANDHELDS;
}

export function isGetHandheldsSuccessAction(action: AnyAction): action is GetHandheldsSuccessAction {
  return action.type === GET_HANDHELDS_SUCCESS;
}

export function isGetHandheldCountSuccessAction(action: AnyAction): action is GetHandheldCountSuccessAction {
  return action.type === COUNT_SUCCESS;
}
