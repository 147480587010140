import { AnyAction } from 'redux';
import { GetTokenFunction, LogoutFunction, UpdateTokenFunction } from './state';

const TOKEN_UPDATE = 'AUTH.UPDATE_TOKEN';

export interface TokenUpdateAction {
  logoutFunction: LogoutFunction;
  type: typeof TOKEN_UPDATE;
  updateTokenFunction: UpdateTokenFunction;
  getTokenFunction: GetTokenFunction;
}

export function updateTokenAction(
    logoutFunction: LogoutFunction,
    updateTokenFunction: UpdateTokenFunction,
    getTokenFunction: GetTokenFunction
): TokenUpdateAction {
  return {
    getTokenFunction,
    logoutFunction,
    type: TOKEN_UPDATE,
    updateTokenFunction
  };
}

export function isTokenUpdate(action: AnyAction): action is TokenUpdateAction {
  return action.type === TOKEN_UPDATE;
}
