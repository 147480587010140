import { AnyAction, combineReducers } from 'redux';
import { isGetSettingsByLocationSuccess } from './actions';
import { LocationSettings } from './state';

const initialState: LocationSettings = {
  settingsByLocationCode: {}
};

// Reducers

function settingsByLocationCode(
  state = initialState.settingsByLocationCode,
  action: AnyAction
) {
  if (isGetSettingsByLocationSuccess(action)) {
    return {
      ...state,
      [action.meta.previousAction.locationCode]: action.payload.data
    };
  }
  return state;
}

export const locationSettings = combineReducers({
  settingsByLocationCode
});
