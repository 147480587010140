import {AnyAction, combineReducers} from 'redux';
import {CustomerRelationshipDto, isAddChildrenSuccess, isGetChildrenSuccess, isGetParentsSuccess} from './actions';
import {CustomerRelationship} from './state';

const initialState: CustomerRelationship = {
  children: {},
  parents: {}
};

export const customerRelationship = combineReducers({
  children,
  parents
});

function children(state = initialState.children, action: AnyAction) {
  if (isGetChildrenSuccess(action) || isAddChildrenSuccess(action)) {
    const customerId = action.meta.previousAction.customerId;
    const childrenCustomer = action.payload.data.map((customer: CustomerRelationshipDto) => customer.customerId);

    if (isGetChildrenSuccess(action)) {
      return { ...state, [ customerId ]: childrenCustomer };
    } else if (isAddChildrenSuccess(action)) {
      return {...state, [customerId]: [ ...(state[customerId] ?? []), ...childrenCustomer]};
    }
  }
  return state;
}

function parents(state = initialState.parents, action: AnyAction) {
  if (isGetParentsSuccess(action)) {
    const customerId = action.meta.previousAction.customerId;

    const parentsCustomer = action.payload.data.map((customer) => customer.customerId);
    return { ...state, [ customerId ]: parentsCustomer };
  }

  return state;
}
