import {default as React} from 'react';
import {Redirect, RouteComponentProps} from 'react-router';
import CustomerList from './customers/CustomerList';
import EditCustomer from './customers/EditCustomer';
import CustomerFeatures from './customers/features/CustomerFeatures';
import NewCustomer from './customers/NewCustomer';
import HandheldList from './handhelds/HandheldList';
import UpdateHandheld from './handhelds/UpdateHandheld';
import EditHub, { Props as EditHubProps } from './hubs/EditHub';
import HubList from './hubs/HubList';
import NewHub from './hubs/NewHub';
import LocationList from './locations/LocationList';

export interface AppRoute {
  path: string;
  exact?: boolean;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export type AppRoutes = AppRoute[];

export const routes: AppRoutes = [
  {
    component: NewCustomer,
    path: '/customers/new'
  },
  {
    component: EditCustomer,
    path: '/customers/:customerId'
  },
  {
    component: CustomerList,
    path: '/customers'
  },
  {
    component: HubList,
    path: '/customers/:customerId/hubs'
  },
  {
    component: CustomerFeatures,
    path: '/customers/:customerId/features'
  },
  {
    component: HandheldList,
    path: '/customers/:customerId/handhelds'
  },
  {
    component: UpdateHandheld,
    path: '/customers/:customerId/handhelds/:handheldId'
  },
  {
    component: NewHub,
    path: '/hubs/new'
  },
  {
    component: HubList,
    path: '/hubs'
  },
  {
    component: LocationList,
    path: '/customers/:customerId/locations'
  },
  {
    component: (props: EditHubProps) => (
      <EditHub
        {...props}
        previousPath="/hubs"
      />
    ),
    path: '/hubs/:hubId'
  },
  {
    component: (props: EditHubProps) => (
      <EditHub
        {...props}
        previousPath={`/customers/${props.match.params.customerId}/hubs`}
      />
    ),
    path: '/customers/:customerId/hubs/:hubId'
  },
  {
    component: () => <Redirect to="/customers"/>,
    path: '/'
  }
];
