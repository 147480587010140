import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const DRAWER_WIDTH = 240;

export default makeStyles((theme: Theme) =>
  createStyles({
    appBar: theme.mixins.toolbar,
    drawer: {
      [theme.breakpoints.up('sm')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
      }
    },
    drawerPaper: {
      width: DRAWER_WIDTH
    },
    nested: {
      paddingLeft: theme.spacing(4)
    },
    sideBarList: {
      paddingTop: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0
      }
    }
  })
);
