import {assoc, union} from 'ramda';
import {AnyAction, combineReducers} from 'redux';
import {HandheldUpdates} from '../state';
import {isGetHandheldUpdateSuccessAction} from './actions';

const initialState: HandheldUpdates = {
  allSerialNumbers: [],
  bySerialNumber: {}
};

export const handheldUpdates = combineReducers({
  allSerialNumbers,
  bySerialNumber
});

function allSerialNumbers(
    state = initialState.allSerialNumbers,
    action: AnyAction
): HandheldUpdates['allSerialNumbers'] {
  if (isGetHandheldUpdateSuccessAction(action)) {
    return union(state, [action.payload.data.serialNumber]);
  }

  return state;
}

function bySerialNumber(state = initialState.bySerialNumber, action: AnyAction): HandheldUpdates['bySerialNumber'] {
  if (isGetHandheldUpdateSuccessAction(action)) {
    const update = action.payload.data;

    return assoc(update.serialNumber, update, state);
  }

  return state;
}
