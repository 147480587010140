import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AxiosDispatch } from '../middleware/axios';
import { State } from '../state';
import { getDescendants, getRootLocation } from './actions';
import { Location, Locations } from './state';
import messages from './translations';

export interface Props {
  customerId: string;
  locations: Locations;
  dispatch: AxiosDispatch & ThunkDispatch<{}, {}, AnyAction>;
  onSelect: (newLocation: string) => void;
}

export const LocationTree = ({customerId, dispatch, locations, onSelect}: Props) => {
  const rootLocationCode = locations.rootLocations[customerId];

  const { formatMessage } = useIntl();

  const [selectedLocation, setSelectedLocation] = useState<string | undefined>();

  useEffect(() => {
    if (!rootLocationCode) {
      dispatch(getRootLocation(customerId));
    }
  }, [customerId, dispatch, rootLocationCode]);

  useEffect(() => {
    if (rootLocationCode && !locations.children[rootLocationCode]) {
      dispatch(getDescendants(rootLocationCode));
    }
  }, [customerId, dispatch, rootLocationCode, locations.children]);

  const handleSelection = useCallback((_: ChangeEvent<{}>, selection: string) => {
    setSelectedLocation(selection);
    onSelect(selection);
  }, [onSelect]);

  const isLoading = !rootLocationCode || !locations.children[rootLocationCode];
  if (isLoading) {
    return (
      <CircularProgress role="progressbar" aria-busy="true"/>
    );
  }

  const TreeNode = ({location}: { location: Location }) => {
    const childCodes = locations.children[location.code] || [];
    const childLocations = childCodes.map(code => locations.byCode[code]);

    return (
      <TreeItem nodeId={location.code} label={locations.byCode[location.code].name}>
        {childLocations.map(child => (<TreeNode key={child.code} location={child}/>))}
      </TreeItem>
    );
  };

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        {
          selectedLocation
            ? (
              <Typography>
                {formatMessage(messages['locations.selectedLocation'], {
                  locationName: locations.byCode[selectedLocation].name
                })}
              </Typography>
            )
            : <Typography>{formatMessage(messages['locations.selectLocation'])}</Typography>
        }
      </AccordionSummary>
      <AccordionDetails>
        {
          isLoading
            ? <CircularProgress/>
            : (
              <TreeView
                onNodeSelect={handleSelection}
                defaultCollapseIcon={<ExpandMoreIcon/>}
                defaultExpandIcon={<ChevronRightIcon/>}
              >
                <TreeNode location={locations.byCode[rootLocationCode]}/>
              </TreeView>
            )
        }
      </AccordionDetails>
    </Accordion>

  );
};

const mapStateToProps = (state: State) => ({
  locations: state.locations
});

const mapDispatchToProps = (dispatch: AxiosDispatch & ThunkDispatch<{}, {}, AnyAction>) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationTree);
