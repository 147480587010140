import {compose, groupBy, indexBy, mapObjIndexed, prop} from 'ramda';
import {AnyAction, combineReducers} from 'redux';
import {RingRelease, RingReleases} from '../state';
import {isGetRingReleasesSuccessAction} from './actions';

const initialState: RingReleases = {
  allVersionNames: [],
  byRing: {},
  byVersionName: {}
};

export const ringReleases = combineReducers({
  allVersionNames,
  byRing,
  byVersionName
});

function allVersionNames(
    state = initialState.allVersionNames,
    action: AnyAction
): RingReleases['allVersionNames'] {
  if (isGetRingReleasesSuccessAction(action)) {
    return action.payload.data.map(handheldRelease => handheldRelease.versionName);
  }

  return state;
}

const groupByVersionName = groupBy<RingRelease>(prop('versionName'));
const indexedHubsToSerialNumbers = mapObjIndexed((releases: RingRelease[]) => releases.map(release => release.name));
const ringReleasesByVersion = compose(indexedHubsToSerialNumbers, groupByVersionName);

function byVersionName(state = initialState.byVersionName, action: AnyAction): RingReleases['byVersionName'] {
  if (isGetRingReleasesSuccessAction(action)) {
    return ringReleasesByVersion(action.payload.data);
  }

  return state;
}

const indexByRing = indexBy<RingRelease>(prop('name'));

function byRing(state = initialState.byRing, action: AnyAction): RingReleases['byRing'] {
  if (isGetRingReleasesSuccessAction(action)) {
    return indexByRing(action.payload.data);
  }

  return state;
}
