import {defineMessages} from 'react-intl.macro';
import en from './translations/en.json';

const translations: Record<string, Record<string, string>> = {
  en,
  'en-GB': {}
};

export const appMessages = defineMessages({
  'app.back': {id: 'app.back', defaultMessage: 'Back'},
  'app.cancel': {id: 'app.cancel', defaultMessage: 'Cancel'},
  'app.rowsPerPage': {id: 'app.rowsPerPage', defaultMessage: 'Rows per page'},
  'app.save': {id: 'app.save', defaultMessage: 'Save'}
});

export default translations;
