import {DateTime} from 'luxon';
import {AnyAction, combineReducers} from 'redux';

import {
  FeatureDto,
  FeatureWithSettingsDto,
  isGetFeaturesSuccessAction,
  isGetFeatureSuccessAction,
  isGetFeaturesWithSettingsSuccessAction
} from './actions';
import {Feature, Features} from './state';

const initialState: Features = {
  allCodes: [],
  byCode: {},
  settingsByCode: {}
};

// Utility functions for parsing raw server responses

function parseFeature(feature: FeatureDto | FeatureWithSettingsDto): Feature {
  return {
    ...feature,
    endDate: feature.endDate == null ? null : DateTime.fromISO(feature.endDate),
    settings: 'settings' in feature // checks whether `feature` is a `FeatureWithSettingsDto`
      ? feature.settings.map((setting) => `${feature.code}.${setting.code}`)
      : null,
    startDate: feature.startDate == null ? null : DateTime.fromISO(feature.startDate)
  };
}
function parseSettings(feature: FeatureWithSettingsDto): Features['settingsByCode'] {
  const featureCode = feature.code;
  const settings: Features['settingsByCode'] = { };
  for (const setting of feature.settings) {
    // settings are indexed by a combination of feature code + setting code
    const code = `${featureCode}.${setting.code}`;
    settings[code] = { ...setting, featureCode };
  }
  return settings;
}

// Reducers

function allCodes(state = initialState.allCodes, action: AnyAction): Features['allCodes'] {
  if (isGetFeaturesSuccessAction(action)) {
    const data = action.payload.data;
    return data.map(f => f.code);
  }

  return state;
}
function byCode(state = initialState.byCode, action: AnyAction): Features['byCode'] {
  if (isGetFeaturesSuccessAction(action)) {
    const updatedFeatures = action.payload.data;
    // entirely replace the previous state so as to not keep any stale data
    const updatedState: Features['byCode'] = { };
    for (const feature of updatedFeatures) {
      const code = feature.code;
      updatedState[code] = parseFeature(feature);
    }
    return updatedState;
  } else if (isGetFeatureSuccessAction(action)) {
    const feature = parseFeature(action.payload.data);
    const code = feature.code;
    // add/replace the entry with the fetched code
    return { ...state, [ code ]: feature };
  }

  return state;
}
// Settings have a (public) compound PK of (code, featureId), so index
function settingsByCode(state = initialState.settingsByCode, action: AnyAction): Features['settingsByCode'] {
  if (isGetFeaturesWithSettingsSuccessAction(action)) {
    const updatedFeatures = action.payload.data;
    // entirely replace the previous state so as not to keep any stale data
    let updatedState: Features['settingsByCode'] = { };
    for (const feature of updatedFeatures) {
      const settings = parseSettings(feature);
      // TODO: putting updatedState second as this used to be a ramda mergeLeft(state, settings)
      //       it had no explanation for why mergeLeft rather than mergeRight, and probably shouldn't
      //       matter so we could probably just keep updatedState const and use Object.assign, but we
      //       would need a test to make us confident in that change
      updatedState = { ...settings, ...updatedState };
    }
    return updatedState;
  } else if (isGetFeatureSuccessAction(action)) {
    const settings = parseSettings(action.payload.data);
    // TODO: this entirely replaces state as that used to be how it worked with ramda, but that
    //       seems wrong. perhaps that's to avoid outdated settings for the feature, since they've
    //       been indexed by a compound key so we can't easily evict existing entries for a specific
    //       feature code?
    return settings;
  }

  return state;
}

export const features = combineReducers({
  allCodes,
  byCode,
  settingsByCode
});
