import {
  ActionDeleteIcon,
  CancelIcon,
  EditIcon,
  SaveIcon
} from '@checkit/react-components/components/Icons';
import {
  Checkbox,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Zoom
} from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import AlertDialog from '../components/alert/AlertDialog';
import { SettingType } from '../features/state';
import { AxiosDispatch } from '../middleware/axios';
import { createSnackbar } from '../notifications/actions';
import {
  deleteLocationSetting,
  isDeleteLocationSettingSuccess,
  isPutLocationSettingSuccess,
  putLocationSetting
} from './actions';
import { LocationSetting } from './state';
import messages from './translations';

export interface Props {
  locationCode: string;
  locationSettings: LocationSetting[];
  dispatch: AxiosDispatch;
}

interface RowModes {
  [key: string]: { mode: 'edit' | 'view' };
}

export const LocationSettings = (props: Props) => {
  const { locationCode, locationSettings, dispatch } = props;
  const { formatMessage } = useIntl();

  const [state, setState] = useState({
    isConfirmingEnable: false,
    isRemove: false,
    isUpdate: false,
    rowModesModel: {} as RowModes,
    rows: locationSettings
  });

  const [localRowsPerPage, setLocalRowsPerPage] = useState(10);
  const [localPage, setLocalPage] = useState(0);
  const [rowsBaseState, setRowsBaseState] = useState<LocationSetting[]>([]);

  const handlePageChange = (event: unknown, page: number) => {
    setLocalPage(page);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalRowsPerPage(+event.target.value);
    setLocalPage(0);
  };

  useEffect(() => {
    const filteredRows = locationSettings.filter((row) => row.isLocation);
    setState((prevState) => ({
      ...prevState,
      rows: filteredRows
    }));
  }, [locationSettings]);

  const handleSaveRow = (rowId: string) => async () => {
    const singleRow = state.rows.find((row) => row.id === rowId);
    if (!singleRow) {
      throw new Error(formatMessage(messages['locations.rowNotFound']));
    }

    setState((prevState) => ({
      ...prevState,
      isConfirmingEnable: true,
      rowModesModel: {
        [rowId]: { mode: 'edit' }
      }
    }));
  };

  const onCancel = () => {
    setState((prevState) => ({
      ...prevState,
      isConfirmingEnable: false,
      rowModesModel: {},
      rows: rowsBaseState
    }));
  };

  const handleConfirm = async (isUpdate: boolean) => {
    const { rows, rowModesModel } = state;
    const found = rows.find((row) => row.id === Object.keys(rowModesModel)[0]);

    if (!found) {
      return;
    }

    let updatedRows: LocationSetting[];
    let message;

    const updatedModes = { ...state.rowModesModel };
    updatedModes[found.id] = { mode: 'view' };

    if (isUpdate) {
      if (!found.locationValue && found.settingType === SettingType.BOOLEAN) {
        found.locationValue = false.toString();
      } else if (!found.locationValue) {
        dispatch(
          createSnackbar({
            message: formatMessage(messages['locations.invalidLocationValue']),
            options: {
              variant: 'error'
            }
          })
        );
        setState((prevState) => ({
          ...prevState,
          isConfirmingEnable: false,
          isRemove: false,
          isUpdate: false,
          rowModesModel: updatedModes
        }));
        return;
      }

      const input = {
        featureCode: found.featureCode,
        locationCode,
        settingCode: found.settingCode,
        value: found.locationValue
      };

      const action = await dispatch(putLocationSetting(input));
      if (isPutLocationSettingSuccess(action)) {
        updatedRows = rows.map((row) => {
          if (row.id === found.id) {
            return {
              ...row,
              locationValue: found.locationValue
            };
          }
          return row;
        });
        message = formatMessage(
          messages['locations.settingUpdatedSuccessfully']
        );
      } else {
        dispatch(
          createSnackbar({
            message: formatMessage(messages['locations.settingFailed']),
            options: {
              variant: 'error'
            }
          })
        );
        return;
      }
    } else {
      updatedRows = rows.map((row) => {
        if (row.id === found.id) {
          return {
            ...row,
            locationValue: ''
          };
        }
        return row;
      });

      const input = {
        featureCode: found!.featureCode,
        locationCode,
        settingCode: found!.settingCode,
        value: found!.locationValue
      };

      const action = await dispatch(deleteLocationSetting(input));
      if (isDeleteLocationSettingSuccess(action)) {
        message = formatMessage(
          messages['locations.settingUpdatedSuccessfully']
        );
      } else {
        dispatch(
          createSnackbar({
            message: formatMessage(messages['locations.settingFailed']),
            options: {
              variant: 'error'
            }
          })
        );
        return;
      }
    }

    setState((prevState) => ({
      ...prevState,
      isConfirmingEnable: false,
      isRemove: false,
      isUpdate: false,
      rowModesModel: updatedModes,
      rows: updatedRows
    }));

    dispatch(
      createSnackbar({
        message,
        options: {
          variant: 'success'
        }
      })
    );
  };

  const onConfirmRemove = () => handleConfirm(false);

  const onConfirmUpdate = () => handleConfirm(true);

  const handleLocationValueChange =
    (rowId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedRows = [...state.rows];
      const foundIndex = updatedRows.findIndex((row) => row.id === rowId);
      if (foundIndex !== -1) {
        updatedRows[foundIndex] = {
          ...updatedRows[foundIndex],
          locationValue:
            updatedRows[foundIndex].settingType === SettingType.BOOLEAN
              ? event.target.checked
                ? 'true'
                : 'false'
              : event.target.value
        };
        setState((prevState) => ({
          ...prevState,
          rowModesModel: {
            [rowId]: { mode: 'edit' }
          },
          rows: updatedRows
        }));
      }
    };

  const handleEditRow = (rowId: string) => async () => {
    setRowsBaseState(state.rows);
    setState((prevState) => ({
      ...prevState,
      isRemove: false,
      isUpdate: true,
      rowModesModel: {
        [rowId]: { mode: 'edit' }
      }
    }));

    const element = document.getElementById(`locationValue-${rowId}`);
    if (element) {
      element.focus();
    }
  };

  const handleDeleteRow = (rowId: string) => async () => {
    setRowsBaseState(state.rows);
    setState((prevState) => ({
      ...prevState,
      isConfirmingEnable: true,
      isRemove: true,
      isUpdate: false,
      rowModesModel: {
        [rowId]: { mode: 'edit' }
      }
    }));
  };

  const handleCancelRow = (rowId: string) => async () => {
    setState((prevState) => ({
      ...prevState,
      rowModesModel: {
        [rowId]: { mode: 'view' }
      },
      rows: rowsBaseState
    }));
  };

  const initValue = (row: LocationSetting) => {
    const rowMode = state.rowModesModel[row.id]?.mode;
    const isEditMode = rowMode === 'edit';

    if (isEditMode) {
      return row.locationValue === 'true';
    }

    if (!row.locationValue) {
      return false;
    }

    return row.locationValue === 'true';
  };

  const initTextValue = (row: LocationSetting) => {
    const rowMode = state.rowModesModel[row.id]?.mode;
    const isEditMode = rowMode === 'edit';

    if (isEditMode) {
      return row.locationValue;
    }

    if (!row.locationValue) {
      return '';
    }

    return row.locationValue;
  };

  const locationValue = (row: LocationSetting) => {
    const isBooleanSetting = row.settingType === SettingType.BOOLEAN;
    const value = isBooleanSetting ? initValue(row) : initTextValue(row);

    return (
      <div>
        {isBooleanSetting ? (
          <Checkbox
            checked={value as boolean}
            onChange={handleLocationValueChange(row.id)}
            id={`locationValue-${row.id}`}
            disabled={state.rowModesModel[row.id]?.mode !== 'edit'}
            color="primary"
          />
        ) : (
          <input
            id={`locationValue-${row.id}`}
            value={(value as string) ?? ''}
            disabled={state.rowModesModel[row.id]?.mode !== 'edit'}
            onChange={handleLocationValueChange(row.id)}
          />
        )}
      </div>
    );
  };

  const rowModeEdit = (row: LocationSetting) => {
    return (
      <>
        <Tooltip
          TransitionComponent={Zoom}
          title={formatMessage(messages['locations.saveTooltipText'])}
          arrow
        >
          <span>
            <IconButton onClick={handleSaveRow(row.id)}>
              <SaveIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          TransitionComponent={Zoom}
          title={formatMessage(messages['locations.cancelTooltipText'])}
          arrow
        >
          <span>
            <IconButton onClick={handleCancelRow(row.id)}>
              <CancelIcon />
            </IconButton>
          </span>
        </Tooltip>
      </>
    );
  };

  const rowModeView = (row: LocationSetting) => {
    return (
      <>
        <Tooltip
          TransitionComponent={Zoom}
          title={formatMessage(messages['locations.editTooltipText'])}
          arrow
        >
          <span>
            <IconButton
              onClick={handleEditRow(row.id)}
              disabled={!row.isLocation}
            >
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          TransitionComponent={Zoom}
          title={formatMessage(messages['locations.deleteTooltipText'])}
          arrow
        >
          <span>
            <IconButton
              onClick={handleDeleteRow(row.id)}
              disabled={
                row.locationValue === undefined || row.locationValue === ''
              }
            >
              <ActionDeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <Typography
        component="h2"
        variant="h5"
        align="left"
        color="textPrimary"
        gutterBottom={true}
      >
        <Icon
          style={{
            alignItems: 'center',
            display: 'inline-flex',
            marginRight: '8px',
            verticalAlign: 'middle'
          }}
        >
          <Settings />
        </Icon>
        <span style={{ marginLeft: '4px', verticalAlign: 'middle' }}>
          {formatMessage(messages['locations.settings'])}
        </span>
      </Typography>
      {state.rows.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {formatMessage(messages['locations.name'])}
                </TableCell>
                <TableCell>
                  {formatMessage(messages['locations.description'])}
                </TableCell>
                <TableCell>
                  {formatMessage(messages['locations.feature'])}
                </TableCell>
                <TableCell>
                  {formatMessage(messages['locations.defaultValue'])}
                </TableCell>
                <TableCell>
                  {formatMessage(messages['locations.customerValue'])}
                </TableCell>
                <TableCell>
                  {formatMessage(messages['locations.locationValue'])}
                </TableCell>
                <TableCell>
                  {formatMessage(messages['locations.action'])}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.settingName}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.featureName}</TableCell>
                  <TableCell>{row.defaultValue}</TableCell>
                  <TableCell>{row.customerValue}</TableCell>
                  <TableCell>
                    <>{locationValue(row)}</>
                  </TableCell>
                  <TableCell>
                    {state.rowModesModel[row.id]?.mode === 'edit'
                      ? { ...rowModeEdit(row) }
                      : { ...rowModeView(row) }}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={state.rows.length}
            rowsPerPage={localRowsPerPage}
            page={localPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableContainer>
      )}
      {state.isUpdate && (
        <AlertDialog
          open={state.isConfirmingEnable}
          title={formatMessage(messages['locations.settingUpdateConfirm'])}
          text={formatMessage(messages['locations.confirmUpdateSetting'])}
          onCancel={onCancel}
          onAccept={onConfirmUpdate}
        />
      )}
      {state.isRemove && (
        <AlertDialog
          open={state.isConfirmingEnable}
          title={formatMessage(messages['locations.settingRemoveConfirm'])}
          text={formatMessage(messages['locations.confirmRemoveSetting'])}
          onCancel={onCancel}
          onAccept={onConfirmRemove}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: AxiosDispatch) => ({
  dispatch
});

export default connect(undefined, mapDispatchToProps)(LocationSettings);
