import { indexBy, partition, pathOr, prop } from 'ramda';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import {
  Grid,
  Typography
} from '@material-ui/core';
import {
  Settings as IconEnabled,
  SettingsOutlined as IconDisabled
} from '@material-ui/icons';

import { FormPage } from '@checkit/react-components';

import { getCustomerFeatures } from '../../customer-features/actions';
import { CustomerSetting } from '../../customer-features/state';
import { getFeatures } from '../../features/actions';
import FeatureComponent, { SettingValueLookup } from '../../features/component/FeatureComponent';
import { Features } from '../../features/state';
import { AxiosDispatch } from '../../middleware/axios';
import { State } from '../../state';
import messages from '../translations';

interface Props extends RouteComponentProps<{ customerId: string }> {
  customerFeatures: string[];
  customerSettings: string[];
  customerSettingsByCode: Record<string, CustomerSetting>;
  features: Features;
  dispatch: AxiosDispatch;
}

export const CustomerFeatures = (props: Props) => {
  const { customerFeatures, customerSettingsByCode, customerSettings, features, dispatch } = props;
  const customerId = props.match.params.customerId;

  const indexedCustomerSettings = indexBy(
    prop('settingCode'),
    customerSettings.map(code => customerSettingsByCode[code])
  );

  useEffect(() => {
    dispatch(getCustomerFeatures(customerId));
  }, [dispatch, customerId]);
  useEffect(() => {
    dispatch(getFeatures(true));
  }, [dispatch]);

  const customerValue: SettingValueLookup = (settingCode) => {
    const customerSettingValue = pathOr(null, [settingCode, 'value'], indexedCustomerSettings);

    if (customerSettingValue === pathOr(null, [settingCode, 'defaultValue'], features.settingsByCode)) {
      return null;
    }

    return customerSettingValue;
  };

  const { formatMessage } = useIntl();

  // sort features alphabetically just in case if they are not in order
  const sortedFeatures = [...features.allCodes].sort(function compare(a, b) {
    const featureA = features.byCode[a];
    const featureB = features.byCode[b];
    return featureA.name.localeCompare(featureB.name);
  });

  // then separate them into those enabled and those disabled
  const [ featuresEnabled, featuresDisabled ] = partition(function isEnabled(feature) {
    return customerFeatures.includes(feature);
  }, sortedFeatures);

  const emptyMessage = (
    <Typography align="center">{formatMessage(messages['customers.features.empty'])}</Typography>
  );

  return (
    <Grid container spacing={4} style={{ flexFlow: 'row-reverse wrap' }}>

      {/* Enabled features. */}
      <Grid item xs={12} lg={6}>
        <FormPage
          size={{ xs: 10, md: 8, lg: 12, xl: 10 }}
          pageTitle={formatMessage(messages['customers.features.enabled'])}
          pageIcon={<IconEnabled />}
        >
          {featuresEnabled.map(featureCode => (
            <FeatureComponent
              key={featureCode}
              enabled={true}
              featureCode={featureCode}
              customerId={props.match.params.customerId}
              settingColumns={[{
                title: formatMessage(messages['customers.customer']),
                value: customerValue
              }]}
            />
          ))}
          {featuresEnabled.length === 0 ? emptyMessage : null}
        </FormPage>
      </Grid>

      {/* Disabled (ie. available) features. */}
      <Grid item xs={12} lg={6}>
        <FormPage
          size={{ xs: 10, md: 8, lg: 12, xl: 10 }}
          pageTitle={formatMessage(messages['customers.features.disabled'])}
          pageIcon={<IconDisabled />}
        >
          {featuresDisabled.map(featureCode => (
            <FeatureComponent
              key={featureCode}
              enabled={false}
              featureCode={featureCode}
              customerId={props.match.params.customerId}
              settingColumns={[{
                title: formatMessage(messages['customers.customer']),
                value: customerValue
              }]}
            />
          ))}
          {featuresDisabled.length === 0 ? emptyMessage : null}
        </FormPage>
      </Grid>

    </Grid>
  );
};

const mapStateToProps = (state: State, props: Props) => ({
  customerFeatures: state.customerFeatures.features[props.match.params.customerId] || [],
  customerSettings: state.customerFeatures.settingsByCustomerId[props.match.params.customerId] || [],
  customerSettingsByCode: state.customerFeatures.settingsByCode,
  features: state.features
});

const mapDispatchToProps = (dispatch: AxiosDispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerFeatures);
