import {useTheme} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import MaterialTable, {Column} from 'material-table';
import {propOr} from 'ramda';
import React, {ChangeEvent, ReactElement, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import caseInsensitiveSort from '../common/case-insensitive-sort';
import {tableIcons} from '../common/material-table-icons';
import TablePaginationActions from '../common/TablePaginationActions';
import {HubActions} from '../hubs/HubActions';
import {Locations} from '../locations/state';
import {AxiosDispatch} from '../middleware/axios';
import {setRowsPerPage} from '../preferences/actions';
import {Preferences} from '../preferences/state';
import {State} from '../state';
import {appMessages} from '../translations';
import {getHandheldCount, getHandhelds} from './actions';
import {Handheld, Handhelds} from './state';
import messages from './translations';

export interface HandheldFormColumn extends Handheld {
  location: string;
}

export interface Props extends RouteComponentProps<{customerId: string}> {
  handhelds: Handhelds;
  locations: Locations;
  dispatch: AxiosDispatch & ThunkDispatch<{}, {}, AnyAction>;
  preferences: Preferences;
}

export const HandheldList = (props: Props) => {
  const {dispatch, handhelds, history, preferences, locations} = props;
  const {formatMessage} = useIntl();

  const rowsPerPage = preferences.rowsPerPage;

  const [page, setPage] = useState(0);

  const customerId = props.match.params.customerId;

  const unknown = formatMessage(messages['handheld.unknown']);

  useEffect(() => {
    if (handhelds.countsByCustomerId[customerId] === undefined) {
      dispatch(getHandheldCount(customerId));
    }
  }, [dispatch, customerId, handhelds.countsByCustomerId]);

  useEffect(() => {
    if (handhelds.countsByCustomerId[customerId] > 0) {
      if (page >= Math.ceil(handhelds.countsByCustomerId[customerId] / rowsPerPage)) {
        // We're off the end of the page range, decrease page (which will run the effect again)
        setPage(page - 1);
      } else {
        dispatch(getHandhelds(customerId, {
          skip: page * rowsPerPage,
          take: rowsPerPage
        }));
      }
    }
  }, [dispatch, customerId, handhelds.countsByCustomerId, page, rowsPerPage]);

  const theme = useTheme();
  const showRowsPerPage = useMediaQuery(theme.breakpoints.up('md'));

  interface ActionProps {
    handheld: Handheld;
  }

  function Actions({handheld}: ActionProps) {
    const onUpdate = () => history.push(`/customers/${handheld.customerId}/handhelds/${handheld.serialNumber}`);

    return (
      <HubActions onUpdate={onUpdate} />
    );
  }

  function handleChangePage(event: any, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    dispatch(setRowsPerPage(Number(event.target.value)));
    setPage(0);
  }

  if (handhelds.countsByCustomerId[customerId] === 0) {
    return (
      <>
        <Container maxWidth="xs">
          <Typography
            component="h1"
            variant="h3"
            align="center"
            color="textPrimary"
            gutterBottom={true}
          >
            {formatMessage(messages['handhelds.noHandhelds'])}
          </Typography>
        </Container>
      </>
    );
  }

  const rows = handhelds.allSerialNumbers.map(serialNumber => {
    const handheld = handhelds.bySerialNumber[serialNumber];
    const location = propOr({}, handheld.locationCode, locations.byCode) as Location;
    return {...handheld, ...{
      location: propOr<string, Location | void, string>(unknown, 'name', location)}};
  });

  const columns: Array<Column<HandheldFormColumn>> = [
    {
      field: 'serialNumber',
      title: formatMessage(messages['handhelds.serialNumber'])
    },
    {
      customSort: (a, b) => caseInsensitiveSort(a.displayName || '', b.displayName || ''),
      field: 'displayName',
      title: formatMessage(messages['handhelds.name'])
    },
    {
      field: 'location',
      title: formatMessage(messages['handhelds.location'])
    },
    {
      render: data => (<Actions handheld={data}/>),
      sorting: false,
      title: formatMessage(messages['handhelds.actions'])
    }
  ];

  const Pagination = (): ReactElement => (
    <TablePagination
      style={{
        float: 'right'
      }}
      rowsPerPageOptions={showRowsPerPage ? [5, 10, 20] : []}
      count={handhelds.countsByCustomerId[customerId] || 0}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: {'aria-label': formatMessage(appMessages['app.rowsPerPage'])},
        native: false
      }}
      onPageChange={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  );

  const onSearchChange = (text: string) => dispatch(getHandhelds(customerId, {
    skip: page * rowsPerPage,
    take: rowsPerPage
  }, text));

  return (
    <MaterialTable
      columns={columns}
      data={rows}
      components={{Pagination}}
      icons={tableIcons}
      isLoading={!handhelds.hasLoaded}
      title={formatMessage(messages['handhelds.title'])}
      options={{
        debounceInterval: 500,
        pageSize: rowsPerPage
      }}
      onSearchChange={onSearchChange}
    />
  );
};

const mapStateToProps = (state: State) => ({
  handhelds: state.handhelds,
  locations: state.locations,
  preferences: state.preferences
});

const mapDispatchToProps = (dispatch: AxiosDispatch & ThunkDispatch<{}, {}, AnyAction>) => ({
  dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HandheldList));
