import {append, merge} from 'ramda';
import {AnyAction, combineReducers} from 'redux';
import { HandheldReleases } from '../state';
import {isGetHandheldReleaseSuccessAction} from './actions';

const initialState: HandheldReleases = {
  allVersionNames: [],
  byHandheldSerialNumber: {},
  byVersionName: {}
};

export const handheldReleases = combineReducers({
  allVersionNames,
  byHandheldSerialNumber,
  byVersionName
});

function allVersionNames(
    state = initialState.allVersionNames,
    action: AnyAction
): HandheldReleases['allVersionNames'] {
  if (isGetHandheldReleaseSuccessAction(action)) {
    return append(action.payload.data.versionName, state);
  }
  return state;
}

function byVersionName(state = initialState.byVersionName, action: AnyAction): HandheldReleases['byVersionName'] {
  if (isGetHandheldReleaseSuccessAction(action)) {
    const release = action.payload.data;
    const indexedByVersionName = {
      [release.versionName]: release
    };
    return merge(state, indexedByVersionName);
  }
  return state;
}

function byHandheldSerialNumber(
  state = initialState.byHandheldSerialNumber,
  action: AnyAction
): HandheldReleases['byHandheldSerialNumber'] {
  if (isGetHandheldReleaseSuccessAction(action)) {
    const serialNumber = action.meta.previousAction.meta.handheldId;
    const release = action.payload.data;
    const indexedBySerialNumber = {
      [serialNumber]: release.versionName
    };
    return merge(state, indexedBySerialNumber);
  }
  return state;
}
