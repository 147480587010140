import {makeStyles, Theme, useTheme} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MaterialTable, {Column} from 'material-table';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';
import caseInsensitiveSort from '../common/case-insensitive-sort';
import {tableIcons} from '../common/material-table-icons';
import {AxiosDispatch} from '../middleware/axios';
import {setRowsPerPage} from '../preferences/actions';
import {Preferences} from '../preferences/state';
import {State} from '../state';
import {getCustomers} from './actions';
import {CustomerActions} from './CustomerActions';
import {Customer, CustomersState, Module} from './state';
import messages from './translations';

export interface Props extends RouteComponentProps {
  customers: CustomersState;
  dispatch: AxiosDispatch;
  preferences: Preferences;
}

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    marginRight: theme.spacing(1)
  }
}));

export function CustomerList(props: Props) {
  const {customers, dispatch, history, preferences} = props;
  const i18n = useIntl();

  const classes = useStyles();

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  const rows = customers.allIds.map(customerId => customers.byId[customerId]);

  const theme = useTheme();
  const showRowsPerPage = useMediaQuery(theme.breakpoints.up('md'));

  const rowsPerPage = preferences.rowsPerPage;

  const ModuleChip = (m: Module) => (
      <Chip key={m} size="small" label={i18n.formatMessage(messages[m])} className={classes.chip} color="primary"/>
  );

  const columns: Array<Column<Customer>> = [
    {
      field: 'customerId',
      title: i18n.formatMessage(messages['customers.customerId'])
    }, {
      customSort: (a, b) => caseInsensitiveSort(a.name, b.name),
      defaultSort: 'asc',
      field: 'name',
      title: i18n.formatMessage(messages['customers.name'])
    }, {
      field: 'type',
      title: i18n.formatMessage(messages['customers.type'])
    }, {
      field: 'isTemplate',
      title: i18n.formatMessage(messages['customers.isTemplate']),
      type: 'boolean'
    }, {
      render: data => data.modules.map(ModuleChip),
      sorting: false,
      title: i18n.formatMessage(messages['customers.modules'])
    }, {
      render: data => (<Actions customer={data}/>),
      sorting: false,
      title: i18n.formatMessage(messages['customers.actions'])
    }
  ];

  function handleChangeRowsPerPage(newValue: number) {
    dispatch(setRowsPerPage(newValue));
  }

  interface ActionProps {
    customer: Customer;
  }

  function Actions({customer}: ActionProps) {
    const onUpdate = () => history.push(`/customers/${customer.customerId}`);
    const onHubs = () => history.push(`/customers/${customer.customerId}/hubs`);
    const onHandhelds = () => history.push(`/customers/${customer.customerId}/handhelds`);
    const onFeatures = () => history.push(`/customers/${customer.customerId}/features`);
    const onLocations = () => history.push(`/customers/${customer.customerId}/locations`);

    return (
      <CustomerActions
        onUpdate={onUpdate}
        onHubs={onHubs}
        onHandhelds={onHandhelds}
        onFeatures={onFeatures}
        onLocations={onLocations}
      />
    );
  }

  return (
      <MaterialTable
          columns={columns}
          data={rows}
          icons={tableIcons}
          isLoading={customers.allIds.length === 0}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          title={i18n.formatMessage(messages['customers.title'])}
          options={{
            pageSize: rowsPerPage,
            pageSizeOptions: showRowsPerPage ? [5, 10, 20] : []
          }}
      />
  );
}

const mapStateToProps = (state: State) => ({
  customers: state.customers,
  preferences: state.preferences
});

const mapDispatchToProps = (dispatch: AxiosDispatch) => ({
  dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerList));
