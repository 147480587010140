import {MessageDescriptor} from 'react-intl';
import {defineMessages} from 'react-intl.macro';

const messages = defineMessages({
  'handheld.androidVersion': {
    defaultMessage: 'Android Version',
    id: 'handheld.androidVersion'
  },
  'handheld.appVersion': {
    defaultMessage: 'Current Installed Version',
    id: 'handheld.appVersion'
  },
  'handheld.auditInformation': {
    defaultMessage: 'Audit Information',
    id: 'handheld.auditInformation'
  },
  'handheld.connected': {
    defaultMessage: 'Connected {humanisedDuration}',
    id: 'handheld.connected'
  },
  'handheld.customerRing': {
    defaultMessage: 'Customer Ring',
    id: 'handheld.customerRing'
  },
  'handheld.desiredVersion': {
    defaultMessage: 'Desired Version',
    id: 'handheld.desiredVersion'
  },
  'handheld.displayName': {
    defaultMessage: 'Memo Name',
    id: 'handheld.displayName'
  },
  'handheld.expectedVersion': {
    defaultMessage: 'Expected Version',
    id: 'handheld.expectedVersion'
  },
  'handheld.expectedVersion.helperText': {
    defaultMessage: 'Expected version is the greater of Desired Version and the Customer Ring',
    id: 'handheld.expectedVersion.helperText'
  },
  'handheld.firmwareVersion': {
    defaultMessage: 'Firmware Version',
    id: 'handheld.firmwareVersion'
  },
  'handheld.form.collapseText': {
    defaultMessage: 'Collapse the details',
    id: 'handheld.form.collapseText'
  },
  'handheld.form.expandText': {
    defaultMessage: 'Expand to view audit details',
    id: 'handheld.form.expandText'
  },
  'handheld.form.helperText': {
    defaultMessage: 'Only displays versions that are greater than the expected version',
    id: 'handheld.form.helperText'
  },
  'handheld.form.required': {
    defaultMessage: 'This field is required',
    id: 'handheld.required'
  },
  'handheld.installedVersion': {
    defaultMessage: 'Installed: {version}',
    id: 'handheld.installedVersion'
  },
  'handheld.isBlocked': {
    defaultMessage: 'Is Blocked',
    id: 'handheld.isBlocked'
  },
  'handheld.lastConnectionAt': {
    defaultMessage: 'Last Connection At',
    id: 'handheld.lastConnectionAt'
  },
  'handheld.latestVersion': {
    defaultMessage: 'The handheld is at the latest version',
    id: 'handheld.latestVersion'
  },
  'handheld.location': {
    defaultMessage: 'Assigned to Location',
    id: 'handheld.location'
  },
  'handheld.macAddress': {
    defaultMessage: 'MAC Address',
    id: 'handheld.macAddress'
  },
  'handheld.modelName': {
    defaultMessage: 'Model Name',
    id: 'handheld.modelName'
  },
  'handheld.noRing': {
    defaultMessage: 'No Ring',
    id: 'handheld.noRing'
  },
  'handheld.none': {
    defaultMessage: 'None',
    id: 'handheld.none'
  },
  'handheld.selectNewVersion': {
    defaultMessage: 'Select New Version',
    id: 'handheld.selectNewVersion'
  },
  'handheld.summary': {
    defaultMessage: 'Summary',
    id: 'handheld.summary'
  },
  'handheld.unknown': {
    defaultMessage: 'Unknown',
    id: 'handheld.unknown'
  },
  'handheld.update': {
    defaultMessage: 'View Handheld',
    id: 'handheld.update'
  },
  'handheld.update.error': {
    defaultMessage: 'Failed to assigned new version to Handheld',
    id: 'handheld.update.error'
  },
  'handheld.update.success': {
    defaultMessage: 'Assigned new version to Handheld',
    id: 'handheld.update.success'
  },
  'handhelds.actions': {
    defaultMessage: 'Actions',
    id: 'handhelds.actions'
  },
  'handhelds.location': {
    defaultMessage: 'Location',
    id: 'handhelds.location'
  },
  'handhelds.name': {
    defaultMessage: 'Name',
    id: 'handhelds.name'
  },
  'handhelds.noHandhelds': {
    defaultMessage: 'No handhelds to display',
    id: 'handhelds.noHandhelds'
  },
  'handhelds.search': {
    defaultMessage: 'Search',
    id: 'handhelds.search'
  },
  'handhelds.serialNumber': {
    defaultMessage: 'Serial Number',
    id: 'handhelds.serialNumber'
  },
  'handhelds.title': {
    defaultMessage: 'Handhelds',
    id: 'handhelds.title'
  }
}) as Record<string, MessageDescriptor>;

export default messages;
