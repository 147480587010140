import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select, {SelectProps} from '@material-ui/core/Select';
import React from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {State} from '../../state';
import {Customer} from '../state';
import messages from '../translations';

export const DEFAULT_TEMPLATE_ID = 'new-customer-template';

interface OwnProps {
  className: string;
  newCustomer: boolean;
  onChange: SelectProps['onChange'];
  templateCustomers?: Customer[];
  selectedCustomerId: string;
}

export interface Props extends OwnProps {
  templateCustomers: Customer[];
}

export const CustomerTemplateSelect = (props: Props) => {
  const {className, templateCustomers, onChange, newCustomer} = props;

  const i18n = useIntl();

  let selectedCustomerId: string;

  if (newCustomer && !props.selectedCustomerId) {
    selectedCustomerId = DEFAULT_TEMPLATE_ID;
  } else {
    selectedCustomerId = props.selectedCustomerId;
  }

  return (
      <FormControl className={className}>
        <InputLabel htmlFor="template-to-add">
          {i18n.formatMessage(
              newCustomer ? messages['customers.chooseTemplate'] : messages['customers.addOptionalTemplate']
          )}
        </InputLabel>
        <Select
            value={selectedCustomerId}
            inputProps={{
              id: 'template-to-add',
              name: 'customerTemplateId'
            }}
            onChange={onChange}
            required={false}
        >
          {!newCustomer ? <MenuItem value={''} /> : null}
          {
            templateCustomers.map(templateCustomer => (
                <MenuItem
                    key={templateCustomer.customerId}
                    value={templateCustomer.customerId}
                >
                  {templateCustomer.name}
                </MenuItem>
            ))
          }
        </Select>
      </FormControl>
  );
};

const mapStateToProps = (state: State, props: OwnProps) => ({
  templateCustomers: props.templateCustomers || Object.values(state.customers.byId).filter(c => c.isTemplate)
});

export default connect(mapStateToProps)(CustomerTemplateSelect);
