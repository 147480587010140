import {assoc, compose, groupBy, indexBy, mapObjIndexed, union} from 'ramda';
import {AnyAction, combineReducers} from 'redux';
import {isCreateHubSuccess, isGetHubsSuccess, isGetHubSuccess, isUpdateHubSuccess} from './actions';
import {Hub, Hubs} from './state';

const initialState: Hubs = {
  allSerialNumbers: [],
  byCustomer: {},
  bySerialNumber: {},
  hasLoaded: false
};

export const hubs =  combineReducers({
  allSerialNumbers,
  byCustomer,
  bySerialNumber,
  hasLoaded
});

function allSerialNumbers(
    state = initialState.allSerialNumbers,
    action: AnyAction
): typeof initialState.allSerialNumbers {
  if (isGetHubsSuccess(action)) {
    return action.payload.data.map(hub => hub.serialNumber);
  } else if (isCreateHubSuccess(action)) {
    return union([action.payload.data.serialNumber], state);
  }

  return state;
}

const indexBySerialNumber = indexBy((hub: Hub) => hub.serialNumber);

function bySerialNumber(state = initialState.bySerialNumber, action: AnyAction): typeof initialState.bySerialNumber {
  if (isGetHubsSuccess(action)) {
    return indexBySerialNumber(action.payload.data);
  } else if (isGetHubSuccess(action) || isCreateHubSuccess(action) || isUpdateHubSuccess(action)) {
    return assoc(action.payload.data.serialNumber, action.payload.data, state);
  }

  return state;
}

const groupByCustomerId = groupBy((hub: Hub) => hub.customerId);
const indexedHubsToSerialNumbers = mapObjIndexed((customerHubs: Hub[]) => customerHubs.map(hub => hub.serialNumber));
const hubSerialNumbersByCustomerId = compose(indexedHubsToSerialNumbers, groupByCustomerId);

function byCustomer(state = initialState.byCustomer, action: AnyAction): typeof initialState.byCustomer {
  if (isGetHubsSuccess(action)) {
    return hubSerialNumbersByCustomerId(action.payload.data);
  } else if (isGetHubSuccess(action) || isCreateHubSuccess(action) || isUpdateHubSuccess(action)) {
    const data = action.payload.data;
    const customerId = data.customerId;
    const serialNumber = data.serialNumber;

    return assoc(customerId, union([serialNumber], state[customerId]), state);
  }

  return state;
}

function hasLoaded(state = initialState.hasLoaded, action: AnyAction): typeof initialState.hasLoaded {
  if (isGetHubsSuccess(action)) {
    return true;
  }

  return state;
}
