import { makeStyles, Theme, Typography } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import React from 'react';
import {useIntl} from 'react-intl';
import {defineMessages} from 'react-intl.macro';
import { RouteComponentProps } from 'react-router';
import { Link as RouterLink, Route } from 'react-router-dom';

const messages = defineMessages({
  'breadcrumb.home': {
    defaultMessage: 'Office',
    id: 'breadcrumb.home'
  }
});

const LinkRouter = (props: any) => <Link {...props} component={RouterLink} />;

const useStyles = makeStyles((theme: Theme) => ({
  breadcrumbs: {
    padding: theme.spacing(2)
  }
}));

export default function RouterBreadcrumbs() {
  const classes = useStyles();

  const {formatMessage} = useIntl();

  return (
    <Route>
      {({location}: RouteComponentProps) => {
        const pathnames = location.pathname.split('/').filter((x: string) => x);
        return (
          <Breadcrumbs maxItems={4} className={classes.breadcrumbs} aria-label="breadcrumb">
            <Typography color="inherit">
              {formatMessage(messages['breadcrumb.home'])}
            </Typography>
            {pathnames.map((value: string, index: number) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;

              return last ? (
                <Typography color="textPrimary" key={to}>
                  {value}
                </Typography>
              ) : (
                <LinkRouter color="inherit" to={to} key={to}>
                  {value}
                </LinkRouter>
              );
            })}
          </Breadcrumbs>
        );
      }}
    </Route>
  );
}
