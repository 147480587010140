import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AnyAction } from 'redux';

import Face from '@material-ui/icons/Face';

import FormPage from '../components/formPage/FormPage';
import config from '../config';
import {
  AxiosAction,
  AxiosDispatch
} from '../middleware/axios';
import { createSnackbar } from '../notifications/actions';

import {
  getCustomers,
  isPutCustomerSuccess,
  putCustomer
} from './actions';
import CustomerForm, { SubmitProps } from './form/CustomerForm';
import CustomerRelationships from './relationships/CustomerRelationships';
import messages from './translations';

const isCustomerRelationshipPanelShown = config.features.customerRelationships;

export interface Props extends RouteComponentProps<{ customerId: string }> {
  dispatch: AxiosDispatch;
}

export const EditCustomer = (props: Props) => {
  const { match, dispatch, history } = props;
  const { formatMessage } = useIntl();

  const customerId = match.params.customerId;

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  const onSubmit = ({ isTemplate, modules, name, customerTemplateId, type }: SubmitProps): Promise<AnyAction> => {
    return dispatch(putCustomer({
      customerId,
      customerTemplateId,
      isTemplate,
      modules,
      name,
      type
    }, history))
      .then((action: AxiosAction) => {
        if (isPutCustomerSuccess(action)) {
          dispatch(createSnackbar({
            message: formatMessage(messages['customers.update.success']),
            options: {
              variant: 'success'
            }
          }));
        } else {
          dispatch(createSnackbar({
            message: formatMessage(messages['customers.update.error']),
            options: {
              variant: 'error'
            }
          }));
        }

        return action;
      });
  };

  return (
    <FormPage pageTitle={formatMessage(messages['customers.editCustomer'])} pageIcon={<Face />}>
      <CustomerForm customerId={customerId} onSubmit={onSubmit} />
      {isCustomerRelationshipPanelShown ? <CustomerRelationships customerId={customerId} /> : null}
    </FormPage>
  );
};

const mapDispatchToProps = (dispatch: AxiosDispatch) => ({
  dispatch
});

export default connect(undefined, mapDispatchToProps)(EditCustomer);
