import {always, assoc, compose, dissoc, identity, indexBy, map, mergeLeft, prop, when} from 'ramda';
import {AnyAction, combineReducers} from 'redux';
import {CustomerDto, isGetCustomersSuccess, isGetCustomerSuccess} from './actions';
import {Customer, CustomersState, Module} from './state';

const initialState: CustomersState = {
  allIds: [],
  byId: {}
};

export const customers =  combineReducers({
  allIds,
  byId
});

// Returns the module name if the flag is true, or undefined otherwise
const hasModuleToModule: (s: Module) => (_: boolean) => Module | void =
    (s: Module) => when(identity, always(s));

// Assign the module array and drop the flag properties
const combineModules: (moduleArray: Module[]) => (_: CustomerDto) => Customer = (moduleArray: Module[]) =>
    compose<CustomerDto, any, any, Customer>(
        dissoc('workManagementEnabled'),
        dissoc('automatedMonitoringEnabled'),
        assoc<Module[], string>('modules', moduleArray)
    );

// Generate the module array and then assign it
const parseModules = (c: CustomerDto) => {
  // The filter removes the falsy values so the cast is valid
  const moduleArray = [
    hasModuleToModule(Module.AUTOMATED_MONITORING)(c.automatedMonitoringEnabled),
    hasModuleToModule(Module.WORK_MANAGEMENT)(c.workManagementEnabled)
  ].filter(Boolean).sort() as Module[];

  return combineModules(moduleArray)(c);
};

const indexByCustomerId = indexBy<Customer>(prop('customerId'));
const parseCustomers = compose(indexByCustomerId, map(parseModules));

function allIds(state = initialState.allIds, action: AnyAction): typeof initialState.allIds {
  if (isGetCustomersSuccess(action)) {
    return action.payload.data.map(c => c.customerId);
  }

  return state;
}

function byId(state = initialState.byId, action: AnyAction): typeof initialState.byId {
  if (isGetCustomersSuccess(action)) {
    return mergeLeft(parseCustomers(action.payload.data), state);
  } else if (isGetCustomerSuccess(action)) {
    const customer = action.payload.data;
    return assoc<Customer, typeof initialState.byId, string>(customer.customerId, parseModules(customer), state);
  }

  return state;
}
