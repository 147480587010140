import {useTheme} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MaterialTable, {Column} from 'material-table';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';
import caseInsensitiveSort from '../common/case-insensitive-sort';
import {tableIcons} from '../common/material-table-icons';
import {AxiosDispatch} from '../middleware/axios';
import {setRowsPerPage} from '../preferences/actions';
import {Preferences} from '../preferences/state';
import {State} from '../state';
import {getHubs} from './actions';
import {HubActions} from './HubActions';
import {Hub, Hubs} from './state';
import messages from './translations';

export interface Props extends RouteComponentProps<{customerId?: string}> {
  hubs: Hubs;
  dispatch: AxiosDispatch;
  preferences: Preferences;
}

export function HubList(props: Props) {
  const {hubs, dispatch, history, preferences, match} = props;
  const i18n = useIntl();

  useEffect(() => {
    if (!hubs.hasLoaded) {
      dispatch(getHubs());
    }
  }, [dispatch, hubs.hasLoaded]);

  let hubsToDisplay: string[];

  const customerId = match.params.customerId;

  if (customerId) {
    hubsToDisplay = hubs.byCustomer[customerId] || [];
  } else {
    hubsToDisplay = hubs.allSerialNumbers;
  }

  const rows = hubsToDisplay.map(serialNumber => hubs.bySerialNumber[serialNumber]);

  const theme = useTheme();
  const showRowsPerPage = useMediaQuery(theme.breakpoints.up('md'));

  const rowsPerPage = preferences.rowsPerPage;

  const columns: Array<Column<Hub>> = [
    {
      field: 'serialNumber',
      title: i18n.formatMessage(messages['hubs.serialNumber'])
    }, {
      customSort: (a, b) => caseInsensitiveSort(a.displayName || '', b.displayName || ''),
      field: 'displayName',
      title: i18n.formatMessage(messages['hubs.name'])
    }, {
      field: 'customerId',
      title: i18n.formatMessage(messages['hubs.customer'])
    }, {
      render: data => (<Actions hub={data}/>),
      sorting: false,
      title: i18n.formatMessage(messages['hubs.actions'])
    }
  ];

  function handleChangeRowsPerPage(newValue: number) {
    dispatch(setRowsPerPage(newValue));
  }

  interface ActionProps {
    hub: Hub;
  }

  function Actions({hub}: ActionProps) {
    const onUpdate = () => history.push(`${history.location.pathname}/${hub.serialNumber}`);

    return (
        <HubActions onUpdate={onUpdate} />
    );
  }

  return (
      <MaterialTable
          columns={columns}
          data={rows}
          icons={tableIcons}
          isLoading={!hubs.hasLoaded}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          title={i18n.formatMessage(messages['hubs.title'])}
          options={{
            pageSize: rowsPerPage,
            pageSizeOptions: showRowsPerPage ? [5, 10, 20] : [],
            // If we haven't loaded we need to set paging to true to avoid a crash in the library
            paging: !hubs.hasLoaded || rows.length > 0
          }}
      />
  );
}

const mapStateToProps = (state: State) => ({
  hubs: state.hubs,
  preferences: state.preferences
});

const mapDispatchToProps = (dispatch: AxiosDispatch) => ({
  dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HubList));
