import DeviceHub from '@material-ui/icons/DeviceHub';
import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {AnyAction} from 'redux';
import FormPage from '../components/formPage/FormPage';
import {getCustomers} from '../customers/actions';
import {AxiosAction, AxiosDispatch} from '../middleware/axios';
import {createSnackbar} from '../notifications/actions';
import {createHub, isCreateHubSuccess} from './actions';
import HubForm, {SubmitProps} from './form/HubForm';
import messages from './translations';

export interface Props extends RouteComponentProps {
  dispatch: AxiosDispatch;
}

export const NewHub = (props: Props) => {
  const {dispatch, history} = props;
  const {formatMessage} = useIntl();

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  const onSubmit = ({
    serialNumber,
    displayName,
    customerId,
    bluetoothMacAddress,
    ethernetMacAddress,
    wifiMacAddress,
    zigbeeMacAddress
  }: SubmitProps): Promise<AnyAction> => {
    return dispatch(createHub({
      bluetoothMacAddress,
      customerId,
      displayName,
      ethernetMacAddress,
      serialNumber,
      wifiMacAddress,
      zigbeeMacAddress
    }))
        .then((action: AxiosAction) => {
          if (isCreateHubSuccess(action)) {
            dispatch(createSnackbar({
              message: formatMessage(messages['hubs.create.success']),
              options: {
                variant: 'success'
              }
            }));
          } else {
            dispatch(createSnackbar({
              message: formatMessage(messages['hubs.create.error']),
              options: {
                variant: 'error'
              }
            }));
          }

          return action;
        });
  };
  const onClose = ( ) => history.push('/hubs');

  return (
      <FormPage pageTitle={formatMessage(messages['hubs.create'])} pageIcon={<DeviceHub/>}>
        <HubForm onSubmit={onSubmit} onClose={onClose} hubSerialNumber={null} />
      </FormPage>
  );
};

const mapDispatchToProps = (dispatch: AxiosDispatch) => ({
  dispatch
});

export default connect(undefined, mapDispatchToProps)(NewHub);
