import {DateTime} from 'luxon';

export enum SettingType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean'
}

export interface Feature {
  code: string;
  name: string;
  description: string | null;
  startDate: DateTime | null;
  endDate: DateTime | null;
  settings: string[] | null;
}

export interface Setting {
  code: string;
  defaultValue: string;
  type: SettingType;
  name: string;
  description: string | null;
  isLocation: boolean;
  isUserServiceable: boolean;
}

export interface Features {
  allCodes: string[];
  byCode: {[key: string]: Feature};
  settingsByCode: {[key: string]: SettingWithFeatureCode};
}

export type SettingWithFeatureCode = Setting & {
  featureCode: string
};
