import {AnyAction} from 'redux';

const SET_ROWS_PER_PAGE = 'PREFERENCES.SET_ROWS_PER_PAGE';

export interface SetRowsPerPageAction extends AnyAction {
  rowsPerPage: number;
  type: typeof SET_ROWS_PER_PAGE;
}

export function setRowsPerPage(rowsPerPage: number) {
  return {
    rowsPerPage,
    type: SET_ROWS_PER_PAGE
  };
}

export function isSetRowsPerPageAction(action: AnyAction): action is SetRowsPerPageAction {
  return action.type === SET_ROWS_PER_PAGE;
}

export interface Setting {
  code: string;
  type: string;
  value: string;
}
