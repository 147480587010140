import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import type { ReactNode } from 'react';

export default function AlertDialog({
  open,
  text,
  title,
  onAccept,
  onCancel
}: {
  /** Whether the dialog is currently open and shown to the user. */
  open: boolean;
  /** The dialog title to be shown to the user. */
  title: string;
  /** The dialog description text to be shown to the user. */
  text: ReactNode | string;
  /** A callback invoked when the user clicks the confirm button. */
  onAccept: () => void;
  /** A callback invoked when the user clicks the cancel button. */
  onCancel: () => void;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
    >
      {title ? <DialogTitle id="alert-dialog-title">{title}</DialogTitle> : null}
      <DialogContent id="alert-dialog-description">
        {typeof text === 'string' ? <DialogContentText>{text}</DialogContentText> : text}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">Cancel</Button>
        <Button onClick={onAccept} color="secondary" autoFocus={true}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}
