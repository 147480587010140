import {AnyAction, combineReducers} from 'redux';
import {isSetRowsPerPageAction} from './actions';
import {Preferences} from './state';

const initialState: Preferences = {
  rowsPerPage: 5
};

export const preferences = combineReducers({
  rowsPerPage
});

function rowsPerPage(state = initialState.rowsPerPage, action: AnyAction) {
  if (isSetRowsPerPageAction(action)) {
    return action.rowsPerPage;
  }

  return state;
}
