import LuxonUtils from '@date-io/luxon';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {SnackbarProvider} from 'notistack';
import React from 'react';
import {IntlProvider} from 'react-intl';
import {BrowserRouter as Router} from 'react-router-dom';
import RouterBreadcrumbs from './components/breadcrumb/Breadcrumb';
import {Content} from './components/content/Content';
import {SideNavbar} from './components/sideNavbar/SideNavbar';
import TopNavbar from './components/topNavbar/TopNavbar';
import Notifications from './notifications/Notifications';

const DEFAULT_LOCALE = 'en-GB';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appBar: theme.mixins.toolbar,
      contentGrid: {
        flexGrow: 1,
        padding: theme.spacing(3)
      },
      root: {
        display: 'flex'
      },
      snackBarSuccess: {
        backgroundColor: theme.palette.primary.main
      },
      snackbarError: {
        backgroundColor: theme.palette.error.main
      }
    })
);

const App = () => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const snackbarClasses = {
    variantError: classes.snackbarError,
    variantSuccess: classes.snackBarSuccess
  };

  return (
      <SnackbarProvider
          maxSnack={1}
          autoHideDuration={5000}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
          classes={snackbarClasses}
      >
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <IntlProvider
              locale={DEFAULT_LOCALE}
              defaultLocale={DEFAULT_LOCALE}
              messages={{}}
          >
            <CssBaseline/>
            <Notifications/>
            <Router basename="/office">
              <div className={classes.root}>
                <TopNavbar handleDrawerToggle={handleDrawerToggle}/>
                <SideNavbar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen}/>
                <div className={classes.contentGrid}>
                  <div className={classes.appBar}/>
                  <Grid container={true}>
                    <RouterBreadcrumbs/>
                    <Content/>
                  </Grid>
                </div>
              </div>
            </Router>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
  );
};

export default App;
