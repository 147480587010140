import { AxiosRequestConfig } from 'axios';
import {AnyAction} from 'redux';
import {SettingType} from '../features/state';
import {AxiosAction, AxiosSuccessAction} from '../middleware/axios';

export interface CustomerFeatureDto {
  code: string;
  settings: CustomerSettingDto[];
}
export interface CustomerSettingDto {
  code: string;
  type: SettingType;
  value: string;
}

// Action Types

const ADD_CUSTOMER_FEATURE = 'CUSTOMERS.ADD_FEATURE';
const ADD_CUSTOMER_FEATURE_SUCCESS = ADD_CUSTOMER_FEATURE + '_SUCCESS';
const GET_CUSTOMER_FEATURES = 'CUSTOMERS.GET_FEATURES';
const GET_CUSTOMER_FEATURES_SUCCESS = GET_CUSTOMER_FEATURES + '_SUCCESS';

// Action Interfaces

export interface GetCustomerFeaturesAction extends AxiosAction {
  /** The customer for whom the request was made. */
  customerId: string;
  type: typeof GET_CUSTOMER_FEATURES;
}
export interface GetCustomerFeaturesSuccessAction extends AxiosSuccessAction<
  CustomerFeatureDto[],
  GetCustomerFeaturesAction
> {
  type: typeof GET_CUSTOMER_FEATURES_SUCCESS;
}

export interface AddCustomerFeatureAction extends AxiosAction {
  /** The customer for whom the request was made. */
  customerId: string;
  type: typeof ADD_CUSTOMER_FEATURE;
}
export interface AddCustomerFeatureSuccessAction extends AxiosSuccessAction<null, AddCustomerFeatureAction> {
  type: typeof ADD_CUSTOMER_FEATURE_SUCCESS;
}

// Action Creators

/** Gets all the features available to the given customer. */

export function getCustomerFeatures(customerId: string): GetCustomerFeaturesAction {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/customers/${customerId}/features`
  };

  return {
    customerId,
    payload: {
      client: 'customerProfile',
      request
    },
    type: GET_CUSTOMER_FEATURES
  };
}

/** Adds the feature with the given code to the specified customer. */
export function addCustomerFeature(customerId: string, feature: string): AddCustomerFeatureAction {
  return {
    customerId,
    payload: {
      client: 'customerProfile',
      request: {
        method: 'PUT',
        url: `/customers/${customerId}/features/${feature}`
      }
    },
    type: ADD_CUSTOMER_FEATURE
  };
}

export function isGetCustomerFeaturesSuccess(action: AnyAction): action is GetCustomerFeaturesSuccessAction {
  return action.type === GET_CUSTOMER_FEATURES_SUCCESS;
}
export function isAddCustomerFeatureSuccessAction(action: AnyAction): action is AddCustomerFeatureSuccessAction {
  return action.type === ADD_CUSTOMER_FEATURE_SUCCESS;
}
