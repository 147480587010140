import {assoc, reject, when} from 'ramda';
import {Key} from 'react';
import {AnyAction} from 'redux';
import {
  isCloseAllSnackbarsAction,
  isCloseSnackbarAction,
  isCreateSnackbarAction, isDisplaySnackbarAction,
  isRemoveSnackbarAction
} from './actions';
import {SnackbarConfig, Snackbars} from './state';

const initialState: Snackbars = [];

const setDismissed = assoc('dismissed', true);
const setDisplayed = assoc('displayed', true);

const setDismissedWhenMatchesKey = (key: Key) => when((s: SnackbarConfig) => s.key === key, setDismissed);
const setDisplayedWhenMatchesKey = (key: Key) => when((s: SnackbarConfig) => s.key === key, setDisplayed);

export function notifications(state: Snackbars = initialState, action: AnyAction): Snackbars {
  if (isCreateSnackbarAction(action)) {
    return state.concat([{
      key: action.key,
      notification: action.notification
    }]);
  } else if (isCloseSnackbarAction(action)) {
    return state.map(setDismissedWhenMatchesKey(action.key));
  } else if (isCloseAllSnackbarsAction(action)) {
    return state.map(setDismissed);
  } else if (isDisplaySnackbarAction(action)) {
    return state.map(setDisplayedWhenMatchesKey(action.key));
  } else if (isRemoveSnackbarAction(action)) {
    return reject(s => s.key === action.key, state);
  }

  return state;
}
