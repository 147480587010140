import {History} from 'history';
import {AnyAction} from 'redux';
import {AxiosAction, AxiosSuccessAction} from '../../middleware/axios';

const GET_HANDHELD_UPDATE = 'HANDHELD_UPDATES.GET';
const GET_HANDHELD_UPDATE_SUCCESS = GET_HANDHELD_UPDATE + '_SUCCESS';
const PUT_HANDHELD_UPDATE = 'HANDHELD_UPDATES.PUT';
const PUT_HANDHELD_UPDATE_SUCCESS = PUT_HANDHELD_UPDATE + '_SUCCESS';

export interface HandheldUpdateDto {
  customerId: string;
  serialNumber: string;
  versionCode: number;
  versionName: string;
}

export interface GetHandheldUpdateAction extends AxiosAction<HandheldUpdateDto> {
  type: typeof GET_HANDHELD_UPDATE;
}

export interface GetHandheldUpdateSuccessAction extends AxiosSuccessAction<HandheldUpdateDto> {
  type: typeof GET_HANDHELD_UPDATE_SUCCESS;
}

export interface PutHandheldUpdateAction extends AxiosAction<HandheldUpdateDto> {
  type: typeof  PUT_HANDHELD_UPDATE;
}

export interface PutHandheldUpdateSuccessAction extends AxiosSuccessAction<HandheldUpdateDto> {
  type: typeof PUT_HANDHELD_UPDATE_SUCCESS;
}

export function getHandheldUpdate(serialNumber: string): GetHandheldUpdateAction {
  return {
    payload: {
      client: 'updateServer',
      request: {
        url: `/handhelds/${serialNumber}`
      }
    },
    type: GET_HANDHELD_UPDATE
  };
}

export function putHandheldUpdate(
  serialNumber: string,
  update: Omit<HandheldUpdateDto, 'serialNumber'>,
  history: History
): PutHandheldUpdateAction {
  return {
    onSuccess: () => dispatch => dispatch(() => history.push(`/customers/${update.customerId}/handhelds`)),
    payload: {
      client: 'updateServer',
      request: {
        data: update,
        method: 'PUT',
        url: `/handhelds/${serialNumber}`
      }
    },
    type: PUT_HANDHELD_UPDATE
  };
}

export function isGetHandheldUpdateSuccessAction(action: AnyAction): action is GetHandheldUpdateSuccessAction {
  return action.type === GET_HANDHELD_UPDATE_SUCCESS;
}

export function isPutHandheldUpdateSuccessAction(action: AnyAction): action is PutHandheldUpdateSuccessAction {
  return action.type === PUT_HANDHELD_UPDATE_SUCCESS;
}
