import {Omit} from '@material-ui/types';
import React from 'react';
import {Link, LinkProps} from 'react-router-dom';

/**
 * A component which allows any Material UI component to act as a link, without becoming an <a> and picking up the
 * corresponding styling.
 *
 * Usage:
 *
 * <Button component={MaterialUiLink}>Click Me</Button>
 *
 * See https://material-ui.com/components/buttons/#ButtonRouter.tsx
 */
export default React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef'>>(
    (props, ref) => <Link innerRef={ref as any} {...props} />
);
