import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Save from '@material-ui/icons/Save';
import clsx from 'clsx';
import {DateTime} from 'luxon';
import {isEmpty, pathOr, propOr} from 'ramda';
import React, {createRef, RefObject, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {SelectValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AnyAction} from 'redux';
import {FormPanel} from '../../../components/form-panel/FormPanel';
import MaterialUiLink from '../../../components/MaterialUiLink';
import {appMessages} from '../../../translations';
import {Handheld, RingRelease} from '../../state';
import messages from '../../translations';

export interface FormField {
  name: string;
  helperText?: string;
  value?: string | boolean;
}
export interface SubmitProps {
  versionCode: number;
  versionName: string;
}

export interface Props {
  availableVersions: Record<string, RingRelease>;
  handheld?: Handheld;
  ringName: string;
  desiredVersion: string;
  expectedVersion: string;
  expectedVersionCode: number;
  location: string;
  customerId: string;
  onSubmit: (data: SubmitProps) => Promise<AnyAction>;
  versionsByRing: Record<string, RingRelease>;
  ringsByVersion: Record<string, string[]>;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  collapseTextFormControl: {
    width: '100%'
  },
  iconSmall: {
    fontSize: 20
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  outerToolBar: {
    margin: theme.spacing(4, 4),
    width: '80%'
  },
  selectVersionList: {
    margin: theme.spacing(3)
  },
  textFormControl: {
    width: '100%'
  },
  toolBar: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
    justifyContent: 'flex-end'
  },
  updateForm: {
    marginTop: theme.spacing(4)
  }
}));

export const HandheldUpdateForm = (props: Props) => {
  const {
    handheld,
    expectedVersion,
    expectedVersionCode,
    desiredVersion,
    availableVersions,
    location,
    ringName,
    customerId,
    ringsByVersion,
    versionsByRing
  } = props;

  const {formatMessage} = useIntl();
  const unknown = formatMessage(messages['handheld.unknown']);
  const noRing = formatMessage(messages['handheld.noRing']);

  const [selectedVersion, setSelectedVersion] = useState(0);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [lastConnectionAt, setLastConnectionAt] = useState(unknown);
  const [shortDate, setShortDate] = useState(unknown);
  const [displayName, setDisplayName] = useState(unknown);
  const [appVersion, setAppVersion] = useState(unknown);

  useEffect(() => {
    if (handheld) {
      setDisplayName(handheld.displayName);
      setAppVersion(handheld.appVersion || unknown);
    }

    if (handheld && handheld.lastConnectionAt) {
      setLastConnectionAt(handheld.lastConnectionAt.toLocaleString(DateTime.DATETIME_SHORT));
      setShortDate(handheld.lastConnectionAt.toRelativeCalendar() as string);
    }
  }, [handheld, unknown]);

  const classes = useStyles();

  const formRef = createRef() as RefObject<ValidatorForm>;

  const onVersionChange = (event: any) => {
    setSelectedVersion(event.target.value);
  };

  const onSubmit = async (event: any) => {
    let isFormValid = false;

    event.preventDefault();

    if (formRef.current) {
      isFormValid = await formRef.current.isFormValid(false);
    }

    if (isFormValid) {
      setFormSubmitting(true);

      const version = Object.values(availableVersions).find(v => v.versionCode === selectedVersion);
      const versionName = propOr<string, RingRelease | void, string>(unknown, 'versionName', version);

      const resultingAction = await props.onSubmit({versionCode: selectedVersion, versionName});

      // If the action was successful, the component won't be mounted and we get a warning
      if (resultingAction.type.indexOf('_SUCCESS') === -1) {
        setFormSubmitting(false);
      }
    }
  };

  const SaveIcon = () => formSubmitting
    ? <CircularProgress className={clsx(classes.leftIcon)} color="inherit" size={12}/>
      : <Save className={clsx(classes.leftIcon)} color="inherit"/>;

  const createFields = ({name, value, helperText}: FormField) => ({
    className: classes.collapseTextFormControl,
    helperText,
    id: name,
    label: formatMessage(messages[`handheld.${name}`]),
    value: (value || propOr<string, Handheld | void, string | boolean>(unknown, name, handheld)).toString()
  });

  const summaryFields = [
    {name: 'displayName'},
    {name: 'macAddress'},
    {name: 'location', value: location},
    {name: 'isBlocked'}
  ].map(createFields);

  const auditFields = [
    {name: 'modelName'},
    {name: 'androidVersion'},
    {name: 'lastConnectionAt', value: lastConnectionAt}
  ].map(createFields);

  const ringWithVersion = `${ringName} (${pathOr(noRing, [ringName, 'versionName'], versionsByRing)})`;

  const versionWithRings = (versionName: string) => {
    const rings = ringsByVersion[versionName] || [];

    let ringsToDisplay: string;

    if (rings.length === 0) {
      ringsToDisplay = noRing;
    } else {
      ringsToDisplay = rings.join(', ');
    }

    return `${versionName} (${ringsToDisplay})`;
  };

  const firmwareFields = [
    {name: 'customerRing', value: ringWithVersion},
    {name: 'desiredVersion', value: versionWithRings(desiredVersion)},
    {name: 'expectedVersion', value: versionWithRings(expectedVersion), helperText: formatMessage(messages['handheld.expectedVersion.helperText'])},
    {name: 'appVersion', value: versionWithRings(appVersion)}
  ].map(createFields);

  return (
    <ValidatorForm ref={formRef} onSubmit={onSubmit} className={classes.updateForm}>
      <FormPanel
        elements={summaryFields}
        heading={formatMessage(messages['handheld.summary'])}
        secondaryHeading={displayName}
        name="handheld-summary"
        initialExpanded={true}
      />
      <FormPanel
        elements={auditFields}
        heading={formatMessage(messages['handheld.auditInformation'])}
        secondaryHeading={formatMessage(messages['handheld.connected'], {humanisedDuration: shortDate})}
        name="audit-information"
        initialExpanded={false}
      />
      <FormPanel
        elements={firmwareFields}
        heading={formatMessage(messages['handheld.firmwareVersion'])}
        secondaryHeading={formatMessage(messages['handheld.installedVersion'], {version: appVersion})}
        name="handheld-details"
        initialExpanded={false}
      >
        <FormGroup row={true} className={classes.selectVersionList}>
          <FormControl className={classes.textFormControl}>
            <SelectValidator
              label={formatMessage(messages['handheld.selectNewVersion'])}
              variant="outlined"
              value={selectedVersion}
              onChange={onVersionChange}
              name="selectVersion"
              validators={['minNumber:1']}
              disabled={isEmpty(availableVersions)}
              errorMessages={formatMessage(messages['handheld.form.required'])}
              helperText={formatMessage(messages['handheld.form.helperText'])}
              inputProps={{
                id: 'new-version'
              }}
            >
              <MenuItem key={'0.0.0'} value={0}>{
                isEmpty(availableVersions) ? formatMessage(messages['handheld.latestVersion']) : formatMessage(messages['handheld.none'])
              }</MenuItem>
              {
                Object.values(availableVersions).map(version => (
                  <MenuItem
                    key={version.versionName}
                    value={version.versionCode}
                    disabled={version.versionCode <= expectedVersionCode}
                  >
                    {`${version.versionName} (${version.name})`}
                  </MenuItem>
                ))
              }
            </SelectValidator>
          </FormControl>
        </FormGroup>
        <Toolbar className={classes.toolBar}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
            disabled={formSubmitting || isEmpty(availableVersions)}
          >
            <SaveIcon/>
            {formatMessage(appMessages['app.save'])}
          </Button>
        </Toolbar>
      </FormPanel>
      <Toolbar className={`${classes.toolBar} ${classes.outerToolBar}`}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          component={MaterialUiLink}
          to={`/customers/${customerId}/handhelds`}
        >
          <ArrowBack className={clsx(classes.leftIcon, classes.iconSmall)}/>
          {formatMessage(appMessages['app.back'])}
        </Button>
      </Toolbar>
    </ValidatorForm>
  );
};
