import {AnyAction} from 'redux';
import {AxiosAction, AxiosSuccessAction} from '../middleware/axios';
import {Feature, Setting} from './state';

export type FeatureDto = Omit<Feature, 'settings' | 'startDate' | 'endDate'> & {
  endDate: string | null,
  startDate: string | null
};
export type FeatureWithSettingsDto = FeatureDto & {
  settings: Setting[];
};

// Action Types

const GET_FEATURES = 'FEATURES.GET_ALL';
const GET_FEATURES_SUCCESS = GET_FEATURES + '_SUCCESS';
const GET_FEATURE = 'FEATURES.GET_ONE';
const GET_FEATURE_SUCCESS = GET_FEATURE + '_SUCCESS';

// Action Interfaces

export interface GetFeaturesAction extends AxiosAction {
  type: typeof GET_FEATURES;
}
export interface GetFeaturesSuccessAction extends AxiosSuccessAction<
  FeatureDto[],
  GetFeaturesAction
> {
  type: typeof GET_FEATURES_SUCCESS;
}
export interface GetFeaturesWithSettingsSuccessAction extends AxiosSuccessAction<
  FeatureWithSettingsDto[],
  GetFeaturesAction
> {
  type: typeof GET_FEATURE_SUCCESS;
}

export interface GetFeatureAction extends AxiosAction {
  type: typeof GET_FEATURE;
}
export interface GetFeatureSuccessAction extends AxiosSuccessAction<FeatureWithSettingsDto> {
  type: typeof GET_FEATURE_SUCCESS;
}

// Action Creators

/** Gets all features available, optionally including their configurable settings. */
export function getFeatures(includeSettings = false): GetFeaturesAction {
  return {
    payload: {
      client: 'customerProfile',
      request: {
        params: { includeSettings },
        url: '/admin/features'
      }
    },
    type: GET_FEATURES
  };
}
/** Gets a single feature with the given code, including its configurable settings. */
export function getFeature(code: string): GetFeatureAction {
  return {
    payload: {
      client: 'customerProfile',
      request: {
        url: `/admin/features/${code}`
      }
    },
    type: GET_FEATURE
  };
}

export function isGetFeaturesSuccessAction(action: AnyAction): action is GetFeaturesSuccessAction {
  return action.type === GET_FEATURES_SUCCESS;
}
export function isGetFeaturesWithSettingsSuccessAction(action: AnyAction):
  action is GetFeaturesWithSettingsSuccessAction {
  return isGetFeaturesSuccessAction(action) && action.meta.previousAction.payload.request.params.includeSettings;
}
export function isGetFeatureSuccessAction(action: AnyAction): action is GetFeatureSuccessAction {
  return action.type === GET_FEATURE_SUCCESS;
}
