import Button from '@material-ui/core/Button';
import {Theme} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useSnackbar} from 'notistack';
import {useEffect} from 'react';
import * as React from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {State} from '../state';
import {closeSnackbar as closeSnackbarAction, displaySnackbar, removeSnackbar} from './actions';
import {Snackbars} from './state';

export interface Props {
  dispatch: Dispatch;
  notifications: Snackbars;
}

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    color: 'white'
  }
}));

export const Notifications = ({notifications, dispatch}: Props) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    const newNotifications = notifications.filter(n => !n.displayed && !n.dismissed);

    newNotifications.forEach(n => {
      const {message, options} = n.notification;

      const dispatchCloseSnackbarAction = () => {
        return dispatch(closeSnackbarAction(n.key));
      };

      enqueueSnackbar(message, {
        ...n.notification.options,
        action: () => (
            <Button className={classes.actions} onClick={dispatchCloseSnackbarAction}>&times;</Button>
        ),
        key: n.key,
        onClose: (event, reason) => {
          if (options && options.onClose) {
            options.onClose(event, reason);
          }

          // Dispatch action to remove snackbar from redux store
          dispatch(removeSnackbar(n.key));
        }
      });

      dispatch(displaySnackbar(n.key));
    });
  }, [notifications, dispatch, enqueueSnackbar, classes]);

  useEffect(() => {
    const dismissedNotifications = notifications.filter(n => n.dismissed);

    dismissedNotifications.forEach(n => {
      closeSnackbar(n.key);
    });
  }, [notifications, closeSnackbar]);

  // Don't render any elements directly - notistack does it
  return null;
};

const mapStateToProps = (state: State) => ({
  notifications: state.notifications
});

const mapDispatchToProps = (dispatch: Dispatch) => ({dispatch});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
