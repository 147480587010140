import {AnyAction} from 'redux';
import {AxiosAction, AxiosSuccessAction} from '../../middleware/axios';

const GET_HANDHELD_RELEASE = 'HANDHELD_RELEASES.GET';
const GET_HANDHELD_RELEASE_SUCCESS = GET_HANDHELD_RELEASE + '_SUCCESS';

export interface HandheldReleaseDto {
  checksum: string;
  versionCode: number;
  versionName: string;
  apkPath: string;
}

export interface GetHandheldReleaseAction extends AxiosAction<HandheldReleaseDto> {
  type: typeof GET_HANDHELD_RELEASE;
  meta: {handheldId: string};
}

export interface GetHandheldReleaseSuccessAction extends AxiosSuccessAction<
  HandheldReleaseDto, GetHandheldReleaseAction
> {
  type: typeof GET_HANDHELD_RELEASE_SUCCESS;
}

export function getHandheldRelease(serialNumber: string): GetHandheldReleaseAction {
  return {
    meta: {
      handheldId: serialNumber
    },
    payload: {
      client: 'updateServer',
      request: {
        url: `/android/releases/latest/${serialNumber}`
      }
    },
    type: GET_HANDHELD_RELEASE
  };
}

export function isGetHandheldReleaseSuccessAction(action: AnyAction): action is GetHandheldReleaseSuccessAction {
  return action.type === GET_HANDHELD_RELEASE_SUCCESS;
}
